import React, { useCallback, useEffect, useState } from 'react';
import {
	Flex,
	Avatar,
	Text,
	HStack,
	IconButton,
	Skeleton,
	VStack,
} from '@chakra-ui/react';
import { Icon } from '../Icon/Icon';
import { colors } from '../../theme/colors';
import { useSelector, useDispatch } from 'react-redux';
import { RootStore } from '../../redux/createStore';
import { TelegramWindowContextType, useTelegram } from '../../services';
import { fetchTgUser } from '../../services/telegram/utils';
import { useQueryClient } from '@tanstack/react-query';
import { updateTelegramUserState } from '../../redux/reducers/telegram-state.reducer';
import { setAvatar } from '../../redux/reducers/avatars.reducer';
import { useTelegramLock } from '../../services/telegram/lockContext';

interface ITeamMemberProps {
	id?: number;
	name: string;
	username?: string;
	avatar?: string;
	onDelete?: VoidFunction;
	includeDelete?: boolean;
}

export const TeamMember: React.FC<ITeamMemberProps> = ({
	id,
	username,
	name,
	onDelete,
	includeDelete,
	avatar,
}) => {
	const tg = useTelegram();
	const lock = useTelegramLock()
	const dispatch = useDispatch();
	const queryClient = useQueryClient();

	const [isLoading, setIsLoading] = useState(false);

	const { isSynced } = useSelector((state: RootStore) => state.syncState);
	const storedAvatar = useSelector(
		(state: RootStore) => state.avatars[id || 0],
	);
	const storedUserData = useSelector(
		(state: RootStore) => state.telegramState.users[id || 0],
	);

	const updateAvatar = useCallback(
		(id: number, avatarUri?: string) => {
			if (!avatarUri?.length) {
				dispatch(setAvatar({ [id]: '' }));
				return;
			}

			if (storedAvatar === avatarUri) {
				return;
			}

			dispatch(setAvatar({ [id]: avatarUri }));
		},
		[storedAvatar, dispatch],
	);

	const updateUserDataOnError = (id: number) => {
		dispatch(
			updateTelegramUserState({
				id,
				data: {
					id,
					username: null,
				},
			}),
		);
		updateAvatar(id, '');
	};

	const fetchTeamMemberById = async (
		id: number,
		tg: TelegramWindowContextType,
	) => {
		try {
			if (storedAvatar !== undefined && !!storedUserData) {
				console.log(
					`information about the user ${id} is already in store`,
				);
				return;
			}
			setIsLoading(true);

			const userResponse = await lock.scheduleEvent(tg => fetchTgUser(queryClient, tg, id));

			if (!userResponse?.userShortInfo) {
				updateUserDataOnError(id);
				return;
			}

			dispatch(
				updateTelegramUserState({
					id,
					data: {
						id,
						username:
							userResponse.userShortInfo.usernames?.[0]
								?.username || null,
					},
				}),
			);

			if (!userResponse?.userFullInfo) {
				console.error(
					`full information about the user ${id} is missing`,
				);
				return;
			}

			const { profilePhoto, fallbackPhoto } = userResponse.userFullInfo;
			const userAvatar =
				profilePhoto?.thumbnail?.dataUri ||
				fallbackPhoto?.thumbnail?.dataUri ||
				'';

			updateAvatar(id, userAvatar);
		} catch (error) {
			console.error('team member fetch error: ', error);
			if (!id) {
				return;
			}
			updateUserDataOnError(id);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		if (tg && id && isSynced) {
			fetchTeamMemberById(id, tg);
		}
	}, [id, tg, isSynced]);

	return (
		<Flex
			justifyContent="space-between"
			align="center"
			p="12px"
			borderBottom="1px solid"
			borderBottomColor="gray.15"
			columnGap="12px"
			_last={{ borderBottom: 'none' }}>
			<HStack spacing="12px">
				<Skeleton
					boxSize="24px"
					borderRadius="50%"
					startColor="transparentLight.10"
					endColor="transparentLight.40"
					isLoaded={!isLoading && isSynced}>
					<Avatar boxSize="24px" src={avatar || storedAvatar} />
				</Skeleton>
				<Skeleton
					width={!isLoading && isSynced ? 'auto' : '170px'}
					borderRadius="2px"
					startColor="transparentLight.10"
					endColor="transparentLight.40"
					isLoaded={!isLoading && isSynced}>
					<VStack spacing={0} align="flex-start">
						<Text
							color="primary"
							fontSize="13px"
							fontWeight="500"
							whiteSpace="nowrap">
							{name}
						</Text>

						<Text color="gray.40" fontSize="13px" fontWeight="400">
							{username ||
								'@' + (storedUserData?.username || 'unknown')}
						</Text>
					</VStack>
				</Skeleton>
			</HStack>
			{includeDelete ? (
				<IconButton
					aria-label="Delete"
					variant="ghost"
					w="24px"
					minW="24px"
					h="24px"
					borderRadius="4px"
					onClick={onDelete}>
					<Icon
						name="delete-red-bin"
						height="16px"
						width="16px"
						color={colors.tertiary}
					/>
				</IconButton>
			) : null}
		</Flex>
	);
};
