import { QueryClient } from '@tanstack/react-query';
import {
	persistQueryClient,
	removeOldestQuery,
} from '@tanstack/react-query-persist-client';
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';

export const persister = createSyncStoragePersister({
	storage: window.localStorage, // window.sessionStorage
	retry: removeOldestQuery,
});

const _queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			staleTime: Infinity,
			// refetchOnMount: false,
			// refetchOnReconnect: false,
			// refetchOnWindowFocus: false,
			gcTime: 1000 * 60 * 60 * 24,
		},
	},
});

export const queryClient = () => {
	persistQueryClient({
		queryClient: _queryClient,
		persister,
		maxAge: 1000 * 60 * 60 * 24,
	});
	return _queryClient;
};
