/* eslint-disable indent */

import { BoardCardItemResponse } from '../features/Dashboard/types';
import { CardDefaultStatusEnum, LabelItemType, TgDataForCard } from '../types';

interface BaseCardsPayload {
	cards: BoardCardItemResponse[];
	statusId?: number;
	activeLabelId?: number;
}

interface UnreadCardsPayload extends BaseCardsPayload {
	tgChats: Record<string, TgDataForCard>;
}

interface UnansweredCardsPayload extends UnreadCardsPayload {
	teamTelegramIds: number[];
}

const checkLabelsForSelectedId = (labels: LabelItemType[], id: number) =>
	labels?.some(label => label.workspaceLabelId === id) ?? false;

const filterCardsByLabel = (
	cards: BoardCardItemResponse[],
	activeLabelId?: number,
) =>
	activeLabelId
		? cards.filter(card =>
				checkLabelsForSelectedId(card.labels, activeLabelId),
			)
		: cards;

const isNotArchived = (card: BoardCardItemResponse) =>
	card.status.typeId !== CardDefaultStatusEnum.ARCHIVE;

const filterByStatus = (card: BoardCardItemResponse, statusId?: number) =>
	statusId ? card.status.id === statusId : true;

export const getAllCards = ({
	cards,
	activeLabelId,
}: Omit<BaseCardsPayload, 'statusId'>) =>
	filterCardsByLabel(cards, activeLabelId);

export const getUnreadCards = ({
	cards,
	tgChats,
	statusId,
	activeLabelId,
}: UnreadCardsPayload) =>
	filterCardsByLabel(cards, activeLabelId).filter(card => {
		const tgChat = tgChats[card.chatTelegramId];
		return (
			tgChat?.unreadCount &&
			isNotArchived(card) &&
			filterByStatus(card, statusId)
		);
	});

export const getUnansweredCards = ({
	cards,
	tgChats,
	teamTelegramIds,
	statusId,
	activeLabelId,
}: UnansweredCardsPayload) =>
	filterCardsByLabel(cards, activeLabelId).filter(card => {
		const tgChat = tgChats[card.chatTelegramId];
		const isLastMessageFromTeam = teamTelegramIds.includes(
			tgChat?.sender?.id || 0,
		);
		return (
			tgChat?.hasAccess &&
			!isLastMessageFromTeam &&
			card.trackedLastTelegramMessageUnixTime !==
				tgChat?.lastMessage?.date &&
			isNotArchived(card) &&
			filterByStatus(card, statusId)
		);
	});

export const getMentionedCards = ({
	cards,
	statusId,
	activeLabelId,
}: BaseCardsPayload) =>
	filterCardsByLabel(cards, activeLabelId).filter(
		card =>
			card.message?.unreadCount &&
			card.currentUserTagged &&
			isNotArchived(card) &&
			filterByStatus(card, statusId),
	);

export const getActiveTasksCards = ({
	cards,
	statusId,
	activeLabelId,
}: BaseCardsPayload) =>
	filterCardsByLabel(cards, activeLabelId).filter(
		card =>
			card?.tasksCount &&
			isNotArchived(card) &&
			filterByStatus(card, statusId),
	);

export const getTeamActivityCards = ({
	cards,
	statusId,
	activeLabelId,
}: BaseCardsPayload) =>
	filterCardsByLabel(cards, activeLabelId).filter(
		card =>
			card?.message?.unreadCount &&
			isNotArchived(card) &&
			filterByStatus(card, statusId),
	);
