import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../redux/createStore';
import { useTelegram } from '../services';
import { setAvatar } from '../redux/reducers/avatars.reducer';
import { fetchTgUser } from '../services/telegram/utils';
import { useQueryClient } from '@tanstack/react-query';
import { useTelegramLock } from '../services/telegram/lockContext';

export const useUserAvatars = (ids?: number[]) => {
	const dispatch = useDispatch();
	const lock = useTelegramLock()
	const queryClient = useQueryClient();
	const tg = useTelegram();

	const avatars = useSelector((state: RootStore) => state.avatars);

	useEffect(() => {
		if (ids?.length && tg) {
			ids.forEach(async id => {
				try {
					if (avatars[id] !== undefined) {
						return;
					}

					const userInfo = await lock.scheduleEvent(tg =>
						fetchTgUser(queryClient, tg, id),
					);

					if (!userInfo) {
						return dispatch(
							setAvatar({
								[id]: '',
							}),
						);
					}
					dispatch(
						setAvatar({
							[id]:
								userInfo.userFullInfo?.profilePhoto?.thumbnail
									?.dataUri ||
								userInfo.userFullInfo?.fallbackPhoto?.thumbnail
									?.dataUri ||
								'',
						}),
					);
				} catch (error) {
					dispatch(
						setAvatar({
							[id]: '',
						}),
					);
				}
			});
		}
	}, [ids, tg]);
};
