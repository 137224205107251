import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { Box, Flex, HStack, VStack, IconButton, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Icon } from '../../Icon/Icon';
import {
	getSystemDefaultStatusData,
	sortCardsByDateAndAccess,
	getUnreadCards,
	getUnansweredCards,
	getMentionedCards,
	getActiveTasksCards,
	getTeamActivityCards,
} from '../../../utils';
import { CustomTag } from '../../CustomTag/CustomTag';
import { useGetWorkspaceCardsStatuses } from '../../../queries';
import { useGetBoardCards } from '../../../features/Dashboard/queries';
import { TaskCardSolo } from '../../TaskCard/TaskCardSolo';
import { TgDataForCard } from '../../../types';
import {
	BoardCardItemResponse,
	BoardFilterValue,
} from '../../../features/Dashboard/types';
import { RootStore } from '../../../redux/createStore';
import { useHandleSwitchingStatuses } from '../hooks';
import { addRecentEntry } from '../../../redux/reducers/search-recent-stack.reducer';
import {
	useGetCardById,
	useGetMatchedCardId,
	useGetMatchedWorkspaceId,
} from '../../../hooks';
import { setCardsStatusId } from '../../../redux/reducers/page-navigation-state.reducer';

interface ISidebarChatViewProps {
	teamTgIds: number[];
}

export const SidebarChatView: React.FC<ISidebarChatViewProps> = ({
	teamTgIds,
}) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const workspaceId = useGetMatchedWorkspaceId();
	const cardId = useGetMatchedCardId();

	const matchedCard = useGetCardById(cardId);

	const chatsCache = useSelector(
		(state: RootStore) => state.telegramState.chats,
	);
	const { filterId, statusId } = useSelector(
		(state: RootStore) => state.pageNavigationState,
	);

	const [isStatusMatched, setIsStatusMatched] = useState(false);

	const hasSetStatusId = useRef(false);

	useEffect(() => {
		if (!hasSetStatusId.current && matchedCard) {
			dispatch(setCardsStatusId(matchedCard.status.id));
			hasSetStatusId.current = true;
			setIsStatusMatched(true);
		}
	}, [matchedCard, dispatch]);

	const { data: statusesData } = useGetWorkspaceCardsStatuses();
	const { data: cardsData } = useGetBoardCards();
	const { nextStatus, prevStatus, canGoNext, canGoPrev } =
		useHandleSwitchingStatuses(
			isStatusMatched ? statusId : null,
			statusesData?.value?.statuses,
		);

	//this is for proper handle status color (text, bg)
	//TODO remove it, after backend include colors into statuses response (same for StatusSelect component on the Chat page)
	const tagData = useMemo(
		() =>
			getSystemDefaultStatusData(statusId, statusesData?.value?.statuses),
		[statusId, statusesData?.value?.statuses],
	);

	const getFilteredCards = useCallback(
		(
			items: BoardCardItemResponse[],
			statusId: number,
			filterId: number,
			tgChats?: Record<string, TgDataForCard>,
			teamTelegramIds?: number[],
		) => {
			switch (filterId) {
				case BoardFilterValue.All:
					return (
						items.filter(elem => elem.status.id === statusId) || []
					);
				case BoardFilterValue.Unread:
					return (
						getUnreadCards({
							cards: items || [],
							tgChats: tgChats || {},
							statusId: statusId,
						}) || []
					);
				case BoardFilterValue.Unanswered:
					return (
						getUnansweredCards({
							cards: items || [],
							tgChats: tgChats || {},
							teamTelegramIds: teamTelegramIds || [],
							statusId: statusId,
						}) || []
					);
				case BoardFilterValue.ActiveTasks:
					return (
						getActiveTasksCards({ cards: items || [], statusId }) ||
						[]
					);
				case BoardFilterValue.TeamActivity:
					return (
						getTeamActivityCards({
							cards: items || [],
							statusId,
						}) || []
					);
				case BoardFilterValue.Mentions:
					return (
						getMentionedCards({
							cards: items || [],
							statusId: statusId,
						}) || []
					);
				default:
					return [];
			}
		},
		[],
	);

	const onRedirect = (obj: {
		cardData: BoardCardItemResponse;
		statusId?: number;
		filterId?: number;
	}) => {
		navigate(`/${workspaceId}/chat/${obj.cardData.cardId}`, {
			state: {
				statusId: obj.statusId,
				filterId: obj.filterId,
				chatTelegramId: obj.cardData.chatTelegramId,
			},
		});
		dispatch(addRecentEntry(obj.cardData));
	};

	const filteredCards = getFilteredCards(
		cardsData?.value?.cards || [],
		statusId,
		filterId - 1,
		chatsCache,
		teamTgIds,
	);

	return (
		<VStack
			spacing={{ base: 3, md: '2px' }}
			mt={{ base: '50px', md: '17px' }}
			mx="auto"
			align="stretch"
			w={{ base: '300px', md: 'full' }}
			h="100%"
			maxH="calc(100vh - 180px)"
			overflowY="hidden"
			isTruncated={true}>
			<Flex flexDirection="row" alignItems="center">
				<IconButton
					aria-label="previous status"
					w="24px"
					h="24px"
					minW="auto"
					borderRadius="4px"
					variant="ghost"
					isDisabled={!canGoPrev}
					onClick={prevStatus}>
					<Icon name="chevron-left" width="16px" height="16px" />
				</IconButton>
				<HStack spacing="8px" ml="4px">
					{tagData ? (
						<CustomTag
							label={tagData.title}
							bgColor={tagData.bgColor}
							labelColor={tagData.textColor}
						/>
					) : null}
					{filteredCards.length ? (
						<Text color="gray.40">{filteredCards.length}</Text>
					) : null}
				</HStack>
				<IconButton
					aria-label="next status"
					w="24px"
					h="24px"
					borderRadius="4px"
					ml="auto"
					minW="auto"
					variant="ghost"
					onClick={nextStatus}
					isDisabled={!canGoNext}>
					<Icon name="chevron-right" width="16px" height="16px" />
				</IconButton>
			</Flex>
			<Box
				w="100%"
				h="100%"
				px={0.5}
				maxH="calc(100vh - 220px)"
				overflowY="auto">
				{filteredCards
					?.sort((cardA, cardB) =>
						sortCardsByDateAndAccess(cardA, cardB, chatsCache),
					)
					.map(card => (
						<TaskCardSolo
							key={card.cardId}
							isDragging={false}
							cardName={card.cardName}
							chatTelegramId={card.chatTelegramId}
							companyChatData={card.message}
							currentUserTagged={card.currentUserTagged}
							onRedirect={() =>
								onRedirect({
									cardData: card,
									filterId: filterId || undefined,
									statusId: card.status.id,
								})
							}
							wrapperProps={{
								mt: 2,
							}}
							cardId={card.cardId}
							trackedLastTelegramMessageUnixTime={
								card.trackedLastTelegramMessageUnixTime
							}
							cardStatusId={card.status.typeId}
							teamTgIds={teamTgIds}
						/>
					))}
			</Box>
		</VStack>
	);
};
