import React, { useState, useCallback, useMemo } from 'react';
import { useCombobox } from 'downshift';
import {
	Box,
	Flex,
	List,
	Text,
	Input,
	InputGroup,
	InputLeftElement,
	InputRightElement,
	Center,
} from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';
import { useHotkeys } from 'react-hotkeys-hook';
import { motion, AnimatePresence } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import Fuse from 'fuse.js';
import { Icon } from '../Icon/Icon';
import {
	getCtrlKeyName,
	appendLastMessageDataToCardForSearch,
} from '../../utils';
import { Command } from './Command';
import { RootStore } from '../../redux/createStore';
import { BoardCardItemResponse } from '../../features/Dashboard/types';
import { colors } from '../../theme/colors';
import { addRecentEntry } from '../../redux/reducers/search-recent-stack.reducer';
import {
	setCardsFilterId,
	setCardsStatusId,
} from '../../redux/reducers/page-navigation-state.reducer';
import { useGetBoardCards } from '../../features/Dashboard/queries';
import { useGetWorkspaceId } from '../../hooks';

export const CommandMenu: React.FC = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const workspaceId = useGetWorkspaceId();
	const { data: cardsData } = useGetBoardCards();

	const { items: recentItems } = useSelector(
		(state: RootStore) => state.searchRecentStack,
	);
	const chatsCache = useSelector(
		(state: RootStore) => state.telegramState.chats,
	);
	// this is needed because downshift`s built in tracking for some reason closes on input click
	const [isOpen, setIsOpen] = useState(false);
	const [searchResults, setSearchResults] = useState<BoardCardItemResponse[]>(
		[],
	);
	const [inputFocused, setInputFocused] = useState(false);

	const { fuse } = useMemo(() => {
		const cards = appendLastMessageDataToCardForSearch(
			cardsData?.value?.cards || [],
			chatsCache,
		);
		return {
			processedCards: cards,
			fuse: new Fuse(cards, {
				keys: ['cardName', 'lastMessage.date'],
				includeScore: true,
				threshold: 0.2,
				sortFn: (a, b) => {
					if (a.score === b.score) {
						return (
							+(b?.item?.[1]?.v || 0) - +(a?.item?.[1]?.v || 0)
						);
					}
					return a.score - b.score;
				},
			}),
		};
	}, [cardsData?.value?.cards, chatsCache]);

	const performSearch = useCallback(
		(value: string) => {
			if (!value.trim()) {
				setSearchResults([]);
				return;
			}
			const results = fuse.search(value);
			setSearchResults(results.map(elem => elem.item));
		},
		[fuse],
	);

	const handleSelectedItemChange = useCallback(
		(item: BoardCardItemResponse) => {
			navigate(`/${workspaceId}/chat/${item.cardId}`, {
				state: {
					chatTelegramId: item.chatTelegramId,
					statusId: item.status.id,
				},
			});
			dispatch(setCardsStatusId(item.status.id));
			dispatch(setCardsFilterId(1));
			dispatch(addRecentEntry(item));
			setIsOpen(false);
		},
		[dispatch, navigate, workspaceId],
	);

	const {
		getMenuProps,
		getInputProps,
		highlightedIndex,
		getItemProps,
		openMenu,
		inputValue,
	} = useCombobox({
		items: searchResults.length ? searchResults : recentItems,
		onSelectedItemChange: changes => {
			if (changes.selectedItem) {
				handleSelectedItemChange(changes.selectedItem);
			}
		},
		onIsOpenChange: changes => {
			if (changes.type === '__input_click__' && inputFocused) {
				return;
			}
			setIsOpen(changes.isOpen || false);
		},
		onInputValueChange: ({ inputValue }) => {
			performSearch(inputValue || '');
		},
		onStateChange: changes => {
			if (changes.type === '__input_keydown_escape__') {
				document.activeElement instanceof HTMLElement &&
					document.activeElement.blur();
				setIsOpen(false);
			}
		},
		itemToString: item => item?.cardName || '',
	});

	useHotkeys(
		['meta+/', 'ctrl+/'],
		() => {
			openMenu();
			setIsOpen(true);
		},
		{ enableOnFormTags: true },
	);

	const suggestions = useMemo(() => {
		if (searchResults.length) {
			return searchResults;
		}
		if (inputValue && recentItems.length) {
			return [];
		}
		return recentItems;
	}, [searchResults, inputValue, recentItems]);

	return (
		<>
			<AnimatePresence>
				{isOpen && (
					<Box
						as={motion.div}
						display="flex"
						key="search-overlay"
						flexDirection="column"
						alignItems="center"
						overflow="hidden"
						position="fixed"
						top="0"
						left="0"
						zIndex="50"
						width="100vw"
						height="100vh"
						userSelect="none"
						backgroundColor={'#0000004D'}
						initial={{ opacity: 0, pointerEvents: 'none' }}
						animate={{ opacity: 1, pointerEvents: 'auto' }}
						exit={{ opacity: 0, pointerEvents: 'none' }}
						onClick={() => setIsOpen(false)}
					/>
				)}
			</AnimatePresence>
			<Box
				p="4px"
				bg={isOpen ? 'gray.10' : undefined}
				borderTopRadius="8px"
				width="100%"
				maxW="368px"
				border="1px solid"
				borderColor={isOpen ? 'gray.15' : 'transparent'}
				borderBottomColor="transparent"
				zIndex={55}
				boxShadow={isOpen ? '2px 4px 16px 0px #0000001F' : undefined}
				position="relative"
				onClick={e => e.stopPropagation()}>
				<Flex
					flexDirection="column"
					justifyContent="center"
					alignSelf="center">
					<InputGroup>
						<InputLeftElement
							height="100%"
							w="16px"
							minW="16px"
							ml="8px">
							<Icon
								name="search"
								width="16px"
								height="16px"
								color={colors.gray[35]}
							/>
						</InputLeftElement>
						<Input
							{...getInputProps({
								onFocus: () => {
									setInputFocused(true);
									setIsOpen(true);
								},
								onBlur: () => {
									setInputFocused(false);
								},
								onKeyDown: event => {
									if (
										event.key === 'Home' ||
										event.key === 'End'
									) {
										event.nativeEvent.preventDownshiftDefault =
											true;
									}
								},
							})}
							placeholder="Search"
							height="28px"
							border="none"
							borderRadius="0"
							fontSize="13px"
							borderBottom="1px solid"
							pl="32px"
							borderBottomColor={
								isOpen ? 'transparent' : 'gray.20'
							}
							color="gray.35"
							_hover={{
								borderBottomColor: isOpen
									? 'gray.10'
									: 'gray.25',
							}}
							_placeholder={{ color: 'gray.35' }}
							_focusVisible={{ outline: 'none' }}
						/>
						<InputRightElement height="100%">
							<Text
								fontSize="13px"
								fontWeight={400}
								color="gray.35">
								{getCtrlKeyName()}+/
							</Text>
						</InputRightElement>
					</InputGroup>
					<AnimatePresence>
						{isOpen && (
							<Box
								as={motion.div}
								initial={{ opacity: 0, y: -10 }}
								animate={{ opacity: 1, y: 0 }}
								exit={{ opacity: 0, y: -10 }}
								w="calc(100% + 2px)"
								position="absolute"
								border="1px solid"
								borderColor="gray.15"
								top="36px"
								left="-1px"
								bg="gray.10"
								borderBottomRadius="8px"
								p="4px">
								{suggestions.length > 0 && (
									<Text
										textTransform="uppercase"
										color="gray.35"
										fontSize="11px"
										py="8px"
										pr="4px"
										pl="10px"
										fontWeight={600}>
										{searchResults.length
											? 'Chats'
											: 'Recent'}
									</Text>
								)}
								<List
									{...getMenuProps()}
									maxH="300px"
									overflowX="auto">
									{suggestions.length > 0 ? (
										suggestions.map((item, index) => (
											<Command
												key={`${item.cardId}-${index}`}
												isHighlighted={
													highlightedIndex === index
												}
												{...getItemProps({
													item,
													index,
												})}
												data={item}
											/>
										))
									) : (
										<Center py="30px">
											<Text
												color="gray.35"
												fontSize="12px">
												No search results
											</Text>
										</Center>
									)}
								</List>
							</Box>
						)}
					</AnimatePresence>
				</Flex>
			</Box>
		</>
	);
};
