import { AnalyticsBrowser } from '@june-so/analytics-next';
import React from 'react';

export function useJune(writeKey: string) {
	const [analytics] = React.useState(() => {
		return AnalyticsBrowser.load({
			writeKey,
		});
	});
	return analytics;
}
