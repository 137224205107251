import React, { useMemo } from 'react';
import { Flex, HStack, Text } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { CustomAvatarGroup, CommandMenu } from '../../components';
import { StatusSelectPopup } from '../../features/Chat/components';
import { RootStore } from '../../redux/createStore';
import {
	useTeamMembersList,
	useGetMatchedWorkspaceId,
	useGetMatchedCardId,
	useGetCardById,
} from '../../hooks';

export const ChatPageHeader: React.FC = () => {
	const workspaceId = useGetMatchedWorkspaceId();
	const cardId = useGetMatchedCardId();

	const matchedCard = useGetCardById(cardId);
	const { workSpace } = useSelector((state: RootStore) => state.auth.user);

	const isTeamChat = useMemo(
		() => workSpace?.teamChat?.cardId === cardId,
		[workSpace?.teamChat?.cardId, cardId],
	);

	const chatTelegramId = useMemo(() => {
		if (!matchedCard?.chatTelegramId) {
			return 0;
		}

		return isTeamChat && workSpace?.teamChat?.id
			? workSpace.teamChat.id
			: matchedCard.chatTelegramId;
	}, [matchedCard, isTeamChat, workSpace?.teamChat?.id]);

	const avatars = useSelector((state: RootStore) => state.avatars);

	const { chatTeamMembers, isUserHasChatAccess } = useTeamMembersList(
		chatTelegramId || 0,
	);

	return (
		<Flex
			align="center"
			py="9px"
			pl={2}
			pr={1}
			id="flex-chat-header-holder">
			<HStack
				minW="200px"
				w="100%"
				spacing={4}
				maxWidth="calc((100vw / 2) - 240px - 184px)">
				{isTeamChat ? null : (
					<>
						<Text fontSize="13px" color="primary" fontWeight="500">
							Status
						</Text>
						<StatusSelectPopup
							workspaceId={workspaceId}
							cardId={cardId}
						/>
					</>
				)}
			</HStack>
			<CommandMenu />
			<HStack
				flex={1}
				flexShrink={0}
				justify="flex-end"
				pl="15px"
				minW="200px">
				{chatTeamMembers?.length && isUserHasChatAccess ? (
					<>
						<Text
							color="secondary"
							fontSize="13px"
							fontWeight="400"
							noOfLines={1}>
							In this chat:
						</Text>
						<HStack spacing={0}>
							<CustomAvatarGroup
								items={chatTeamMembers}
								avatars={avatars}
							/>
						</HStack>
					</>
				) : null}
			</HStack>
		</Flex>
	);
};
