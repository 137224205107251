import React from 'react';
import { useTelegram } from '../../../services';
import { fetchTgFullUser } from '../../../services/telegram/utils';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import {
	ApiChat,
	ApiUser,
	ApiUserFullInfo,
} from '../../../../../telegram-tt/src/api/types';
import { sixHoursInMilliseconds } from '../../../constants';
import { useSelector } from 'react-redux';
import { RootStore } from '../../../redux/createStore';
import { useTelegramLock } from '../../../services/telegram/lockContext';

type FullUserResponseData = {
	user: ApiUser;
	fullInfo: ApiUserFullInfo;
	users: (ApiUser | undefined)[];
	chats: (ApiChat | undefined)[];
};

type IPayload = {
	chatId: number;
	senderId?: number;
	accessHash?: string;
	isEnabled: boolean;
};

export const useGetSenderAvatar = async ({
	chatId,
	senderId,
	accessHash,
	isEnabled,
}: IPayload) => {
	const tg = useTelegram();
	const queryClient = useQueryClient();
	const lock = useTelegramLock();
	const isSynced = useSelector(
		(state: RootStore) => state.syncState.isSynced,
	);

	const extractAvatarFromFullInfo = (
		fullUserInfo?: FullUserResponseData | null,
	): string | null => {
		if (!fullUserInfo?.fullInfo) {
			return null;
		}

		const { profilePhoto, fallbackPhoto } = fullUserInfo.fullInfo;
		return (
			profilePhoto?.thumbnail?.dataUri ||
			fallbackPhoto?.thumbnail?.dataUri ||
			''
		);
	};

	const res = useQuery({
		queryKey: ['tg-api-sender-avatar', senderId],
		queryFn: async () => {
			if (!tg || !senderId || !accessHash || !chatId) {
				return null;
			}

			const fullUserInfo = await lock.scheduleEvent(tg =>
				fetchTgFullUser(
					queryClient,
					tg,
					senderId.toString(),
					accessHash,
				),
			);

			return fullUserInfo;
		},
		enabled: isEnabled && !!tg && !!senderId && !!accessHash && isSynced,
		staleTime: sixHoursInMilliseconds,
		refetchOnWindowFocus: false,
	});

	return extractAvatarFromFullInfo(res?.data);
};
