import React from 'react';
import { Box, Flex, FormLabel } from '@chakra-ui/react';
import { RadixCheckbox } from '../../../components';

interface IFolderItemProps {
	name: string;
	value: string;
	checked: boolean;
	onCheckedChange: (value: boolean) => void;
}

export const FolderItem: React.FC<IFolderItemProps> = ({
	name,
	value,
	checked,
	onCheckedChange,
}) => {
	return (
		<Flex
			p="14px"
			w="100%"
			borderBottom="1px solid"
			borderColor="gray.20"
			align="center"
			gap="16px"
			_last={{ borderBottom: 'none' }}>
			<Box w="16px" h="16px">
				<RadixCheckbox
					name={name}
					value={value}
					checked={checked}
					onCheckedChange={onCheckedChange}
					id={`check-${value}`}
				/>
			</Box>
			<FormLabel
				color="primary"
				m={0}
				htmlFor={`check-${value}`}
				fontSize="13px"
				lineHeight="20px"
				cursor="pointer"
				w="full"
				fontWeight={500}>
				{name}
			</FormLabel>
		</Flex>
	);
};
