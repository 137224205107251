import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type InitialState = {
	telegramConnected: boolean;
	showTelegramLoginModal: boolean;
	userId: string | null;
};

const initialState: InitialState = {
	telegramConnected: false,
	showTelegramLoginModal: false,
	userId: null,
};

const loginFlowReducer = createSlice({
	name: 'login-flow',
	initialState,
	reducers: {
		setShowTelegramModal: (state, action: PayloadAction<boolean>) => {
			state.showTelegramLoginModal = action.payload;
		},
		setTelegramConnected: (
			state,
			action: PayloadAction<{ isConnected: boolean; userId?: string }>,
		) => {
			state.telegramConnected = action.payload.isConnected;
			state.userId = action.payload.userId || null;
		},
	},
});

export const { setShowTelegramModal, setTelegramConnected } =
	loginFlowReducer.actions;

export default loginFlowReducer.reducer;
