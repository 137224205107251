import { BoardCardItemResponse } from '../features/Dashboard/types';
import { TgDataForCard } from '../types';

export function appendLastMessageDataToCardForSearch(
	cards: BoardCardItemResponse[],
	tgChatsData: Record<string, TgDataForCard>,
) {
	return cards.map(elem => ({
		...elem,
		lastMessage: tgChatsData[elem.chatTelegramId]?.lastMessage,
	}));
}
