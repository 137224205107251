import React from 'react';
import { Box } from '@chakra-ui/react';
import { Draggable } from 'react-beautiful-dnd';
import { TaskCardSolo } from '../../../components';
import { BoardCardItemResponse } from '../types';

interface DraggableTaskCardProps {
	task: BoardCardItemResponse;
	index: number;
	columnStatusId: number;
	tabIndex: number;
	teamTgIds: number[];
	onRedirect: (obj: {
		cardData: BoardCardItemResponse;
		statusId?: number;
		filterId?: number;
	}) => void;
}

export const DraggableTaskCard = React.memo<DraggableTaskCardProps>(
	({ task, index, columnStatusId, tabIndex, teamTgIds, onRedirect }) => {
		return (
			<Draggable
				key={task.cardId}
				draggableId={`${task.cardId}`}
				index={index}>
				{(provided, snapshot) => (
					<Box
						ref={provided.innerRef}
						mb={1.5}
						p={0.5}
						style={{
							...provided.draggableProps.style,
							transition: snapshot.isDragging
								? 'none'
								: 'all 0.2s ease-in-out',
						}}
						{...provided.draggableProps}
						{...provided.dragHandleProps}>
						<TaskCardSolo
							isDragging={snapshot.isDragging}
							cardName={task.cardName}
							chatTelegramId={task.chatTelegramId}
							onRedirect={() =>
								onRedirect({
									cardData: task,
									statusId: columnStatusId,
									filterId: tabIndex + 1,
								})
							}
							companyChatData={task.message}
							currentUserTagged={task.currentUserTagged}
							trackedLastTelegramMessageUnixTime={
								task.trackedLastTelegramMessageUnixTime
							}
							cardId={task.cardId}
							cardStatusId={task.status.typeId}
							teamTgIds={teamTgIds}
							labels={task.labels}
						/>
					</Box>
				)}
			</Draggable>
		);
	},
);
