import { configureStore } from '@reduxjs/toolkit';
import {
	persistStore,
	persistReducer,
	FLUSH,
	REHYDRATE,
	PAUSE,
	PERSIST,
	PURGE,
	REGISTER,
} from 'redux-persist';

import rootReducer from './reducers/root.reducer';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

import { get, set, del } from 'idb-keyval';

// Custom storage engine for IndexedDB using idb-keyval
const indexedDBStorage = {
	getItem: async (key: string) => {
		try {
			const value = await get(key);
			return value;
		} catch (err) {
			console.error('Error loading from IndexedDB:', err);
			return null;
		}
	},
	setItem: async (key: string, value: unknown) => {
		try {
			await set(key, value);
		} catch (err) {
			console.error('Error saving to IndexedDB:', err);
		}
	},
	removeItem: async (key: string) => {
		try {
			await del(key);
		} catch (err) {
			console.error('Error removing from IndexedDB:', err);
		}
	},
};

const currentPersistVersion = 15;

const persistedReducer = persistReducer<ReturnType<typeof rootReducer>>(
	{
		key: 'root',
		version: currentPersistVersion,
		storage: indexedDBStorage,
		stateReconciler: autoMergeLevel2,
		migrate: state => {
			const persistedStateVersion = state?._persist.version;
			console.log('Migration Running:', {
				currentPersistVersion,
				persistedStateVersion,
			});

			if (
				persistedStateVersion &&
				persistedStateVersion !== currentPersistVersion
			) {
				console.log('Cache outdated, resetting.');

				return Promise.resolve(undefined);
			}

			return Promise.resolve(state);
		},
		whitelist: [
			'auth',
			'avatars',
			'telegramState',
			'pageNavigationState',
			'searchRecentStack',
			'dashboardState',
		],
	},
	rootReducer,
);

const store = configureStore({
	reducer: persistedReducer,

	middleware: getDefaultMiddleware =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [
					FLUSH,
					REHYDRATE,
					PAUSE,
					PERSIST,
					PURGE,
					REGISTER,
				],
			},
		}),
});

const persistor = persistStore(store);

export type RootStore = ReturnType<typeof store.getState>;

export { store, persistor };
