import React from 'react';
import { useTelegramLock } from '../../services/telegram/lockContext';

export const IframeLockWrapper: React.FCC<{ iframeOpened: boolean }> = ({
	children,
	iframeOpened,
}) => {
	const lock = useTelegramLock();

	React.useEffect(() => {
		if (iframeOpened) {
			lock.acquireUiLock();
		}

		return () => lock.releaseUiLock();
	}, [iframeOpened]);

	const locked = lock.isLocked && iframeOpened;
	return (
		<React.Fragment>
			{children}
			{locked && (
				<div
					style={{
						position: 'absolute',
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						backgroundColor: 'rgba(255, 255, 255, 0.5)',
						pointerEvents: 'all',
					}}>
					<button onClick={() => lock.releaseLock()}>unlock</button>
				</div>
			)}
		</React.Fragment>
	);
};
