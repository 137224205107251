import { useMutation } from '@tanstack/react-query';
import { axiosWithAuth, LegacyApiResponse } from '../../axios';

interface ExchangeOtpPayload {
	telegramUserId: number;
	otpCode: string;
}

interface ExchangeOtpResult {
	token: {
		accessToken: string;
		accessTokenExpiration: string;
		refreshToken: string;
	};
	user: {
		avatarThumbnailLink: string | null;
		avatarUrl: string | null;
		firstName: string | null;
		id: number;
		telegramUserId: number;
		lastName: string | null;
		isOnboarded: boolean;
		workSpace: {
			id: number;
			name: string;
			avatarUrl: string | null;
			avatarThumbnailUrl: string | null;
			teamChat: {
				id: number;
				name: string;
				cardId: number;
			} | null;
			isArchiveVisible: boolean;
			isAdmin: boolean;
		} | null;
		timeZoneId: string | null;
	};
}

const exchangeOtp = async ({ telegramUserId, otpCode }: ExchangeOtpPayload) => {
	const { data } = await axiosWithAuth.post<
		LegacyApiResponse<ExchangeOtpResult>
	>('/auth/exchange', {
		telegramUserId,
		otpCode,
	});

	return data.value;
};

const EXCHANGE_OTP_MUTATION = 'EXCHANGE_OTP_MUTATION';

function useExchangeOtp() {
	return useMutation<ExchangeOtpResult, unknown, ExchangeOtpPayload>({
		mutationKey: [EXCHANGE_OTP_MUTATION],
		mutationFn: exchangeOtp,
	});
}

export { exchangeOtp, useExchangeOtp, EXCHANGE_OTP_MUTATION };
