import axios, {
	AxiosRequestConfig,
	AxiosResponse,
	AxiosError,
	InternalAxiosRequestConfig,
} from 'axios';
import { store } from '../redux/createStore';
import { Alerter, setAuthHeaderToken } from '../utils';
import { refreshToken, logout } from '../redux/reducers/auth.reducer';
import { ApiResponse, RefreshResponse } from '../types';
import axiosRetry from 'axios-retry';

interface RetryQueueItem {
	resolve: (value?: unknown) => void;
	reject: (error?: unknown) => void;
	config: AxiosRequestConfig;
}

interface ExtendedAxiosRequestConfig extends AxiosRequestConfig {
	_retry?: boolean;
}

const networkErrors = ['ECONNABORTED', 'ERR_NETWORK'];

const alertState = { isShown: false };

const logoutUser = (disableDefaultAlert?: boolean) => {
	store.dispatch(logout());
	setAuthHeaderToken(null);
	if (disableDefaultAlert || alertState.isShown) {
		return;
	}
	Alerter.error('You need to login again to continue');
};

axiosRetry(axios, {
	retries: 3,
	retryDelay: retryCount => {
		return retryCount * 1000;
	},
	retryCondition: error => {
		return (
			axiosRetry.isNetworkError(error) ||
			networkErrors.includes(error.code || '')
		);
	},

	onMaxRetryTimesExceeded: () => {
		if (alertState.isShown) {
			return;
		}
		Alerter.error(
			'Something went wrong with your internet connection. Please check it and try to login again',
		);
		logoutUser(true);
		alertState.isShown = true;
	},
});

export default function intercept(): void {
	axios.defaults.baseURL =
		import.meta.env.VITE_API_BASE_URL || 'http://localhost:5173';

	let isRefreshing = false;
	const refreshAndRetryQueue: RetryQueueItem[] = [];

	axios.interceptors.request.use(async (req: InternalAxiosRequestConfig) => {
		const accessToken = store.getState().auth?.token?.accessToken;

		if (accessToken) {
			if (req.headers) {
				req.headers.Authorization = `Bearer ${accessToken}`;
			} else {
				delete req.headers['Authorization'];
			}
		}
		return req;
	});

	axios.interceptors.response.use(
		(response: AxiosResponse) => {
			alertState.isShown = false;
			return response;
		},
		async (error: AxiosError) => {
			try {
				const { config, response } = error;
				const status = response?.status;

				const extendedConfig = config as ExtendedAxiosRequestConfig;

				if (
					status === 401 &&
					extendedConfig &&
					!extendedConfig._retry
				) {
					if (isRefreshing) {
						return new Promise<unknown>((resolve, reject) => {
							refreshAndRetryQueue.push({
								config: extendedConfig,
								resolve,
								reject,
							});
						});
					}

					extendedConfig._retry = true;
					isRefreshing = true;

					try {
						const refreshResult = await axios.post<
							ApiResponse<RefreshResponse>
						>('/api/auth/refresh-token', {
							accessToken:
								store.getState().auth?.token?.accessToken,
							refreshToken:
								store.getState().auth?.token?.refreshToken,
						});

						if (!refreshResult?.data?.success) {
							throw new Error('Refresh token failed');
						}

						setAuthHeaderToken(
							refreshResult.data.value.accessToken,
						);
						store.dispatch(refreshToken(refreshResult.data.value));

						refreshAndRetryQueue.forEach(
							({ config: retryConfig, resolve, reject }) => {
								axios
									.request(retryConfig)
									.then(resolve)
									.catch(reject);
							},
						);

						return axios(extendedConfig);
					} catch (refreshError) {
						console.log('USER LOGOUT ON ERROR: ', refreshError);
						logoutUser();
						return Promise.reject(refreshError);
					} finally {
						isRefreshing = false;
						refreshAndRetryQueue.length = 0;
					}
				}

				return Promise.reject(error);
			} catch (interceptorError) {
				console.error('Error in interceptor:', interceptorError);
				return Promise.reject(error);
			}
		},
	);
}
