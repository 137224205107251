import React from 'react';
import { TelegramWindowContextType } from './context';

type LockId = string;

type EventSchedulerOptions = {
	operationTimeoutMs?: number;
	lockTimeoutMs?: number;
};

type ScheduleOptions = EventSchedulerOptions & {
	isBackground?: boolean;
};

type EventFunction<T> = (tgCtx: TelegramWindowContextType) => Promise<T>;

type EventScheduler = <T>(
	event: EventFunction<T>,
	options?: EventSchedulerOptions,
) => Promise<T | null>;

type LockOptions = {
	releaseLock: () => void;
	isLocked: boolean;

	acquireUiLock: () => void;
	releaseUiLock: () => void;
	isLockedByUi: boolean;

	scheduleEvent: EventScheduler;
	scheduleBackgroundEvent: EventScheduler;
};

type EventHandler<T> = () => Promise<T>;

const TelegramLockContext = React.createContext<LockOptions | undefined>(
	undefined,
);

class Deferred<T = void> {
	promise: Promise<T>;
	reject!: (reason?: unknown) => void;
	resolve!: (value: T | PromiseLike<T>) => void;

	constructor() {
		this.promise = new Promise((resolve, reject) => {
			this.reject = reject;
			this.resolve = resolve;
		});
	}
}

const generateId = () => Math.random().toString(16).slice(2);

export const TelegramLockContextProvider: React.FCC<{
	tg?: TelegramWindowContextType;
}> = ({ children, tg }) => {
	const currentLockId = React.useRef<LockId | null>(null);
	const [isLocked, setIsLocked] = React.useState(false);

	const [isLockedByUi, setIsLockedByUi] = React.useState(false);
	const isLockedByUiRef = React.useRef(false);

	const tgRef = useLatestRef(tg);

	const eventQueue = React.useRef<LockId[]>([]);
	const backgroundQueue = React.useRef<LockId[]>([]);
	const eventsMap = React.useRef<
		Record<
			LockId,
			{
				execute: EventHandler<unknown>;
				cancel: EventHandler<unknown>;
			}
		>
	>({});

	const acquireLock = (lockId: LockId) => {
		currentLockId.current = lockId;
		setIsLocked(true);
	};

	const releaseLock = (lockId: LockId) => {
		if (currentLockId.current === lockId) {
			currentLockId.current = null;
			setIsLocked(false);
			processNextEvent();
		}
	};

	const cancelEvent = (lockId: LockId) => {
		const event = eventsMap.current[lockId];
		if (event) {
			event.cancel();
		}
	};

	const processQueue = (queue: LockId[], queueName: string) => {
		if (!queue?.length) {
			return;
		}

		const lockId = queue.shift();
		console.log(
			`Processing ${queueName} queue size: ${queue.length}, lockId: ${lockId}`,
		);
		processEvent(lockId);
	};

	const processEvent = (lockId?: LockId) => {
		if (!lockId) {
			return;
		}

		const event = eventsMap.current[lockId];
		if (!event) {
			return processNextEvent();
		}

		event.execute();
	};

	const processNextEvent = () => {
		if (currentLockId.current) {
			return;
		}

		processQueue(eventQueue.current, 'event');

		if (!isLockedByUiRef.current) {
			processQueue(backgroundQueue.current, 'background');
		}
	};

	const setOperationTimeout = (
		lockId: LockId,
		options?: EventSchedulerOptions,
	) => {
		const timeout = options?.operationTimeoutMs || 15_000;

		return setTimeout(() => {
			cancelEvent(lockId);
			releaseLock(lockId);
		}, timeout);
	};

	const setLockTimeout = (
		lockId: string,
		options?: EventSchedulerOptions,
	) => {
		const timeout = options?.lockTimeoutMs;
		if (!timeout) {
			return null;
		}

		return setTimeout(() => {
			cancelEvent(lockId);
		}, timeout);
	};

	const scheduleEventBase = async <T,>(
		event: EventFunction<T>,
		options?: ScheduleOptions,
	) => {
		const tg = tgRef.current;
		if (!tg) {
			console.warn('scheduleEventBase: tg is not defined');
			return Promise.resolve(null);
		}

		if (!eventQueue.current || !backgroundQueue.current) {
			console.warn('Queue not initialized');
			return Promise.resolve(null);
		}

		try {
			const lockId = generateId();
			const deferred = new Deferred<T | null>();

			const targetQueue = options?.isBackground
				? backgroundQueue.current
				: eventQueue.current;

			targetQueue.push(lockId);

			const lockTimeout = setLockTimeout(lockId, options);

			eventsMap.current[lockId] = {
				execute: async () => {
					cleanupTimeout(lockTimeout);
					acquireLock(lockId);

					const operationTimeout = setOperationTimeout(
						lockId,
						options,
					);

					try {
						if (!tgRef.current) {
							deferred.resolve(null);
							return;
						}
						const result = await event(tgRef.current);
						deferred.resolve(result);
					} catch (err) {
						console.error('Error in scheduled event:', err);
						deferred.resolve(null);
					} finally {
						cleanupTimeout(operationTimeout);
						delete eventsMap.current[lockId];
						releaseLock(lockId);
					}
				},
				cancel: async () => {
					delete eventsMap.current[lockId];
					deferred.resolve(null);
				},
			};

			processNextEvent();
			return deferred.promise;
		} catch (err) {
			console.error('Error in scheduleEventBase:', err);
			return Promise.resolve(null);
		}
	};

	return (
		<TelegramLockContext.Provider
			value={{
				isLocked,
				releaseLock: () => {
					if (currentLockId.current) {
						cancelEvent(currentLockId.current);
						releaseLock(currentLockId.current);
					}
				},
				acquireUiLock: () => {
					isLockedByUiRef.current = true;
					setIsLockedByUi(true);
				},
				releaseUiLock: () => {
					setIsLockedByUi(false);
					isLockedByUiRef.current = false;
					processNextEvent();
				},
				isLockedByUi,
				scheduleEvent: (event, options) =>
					scheduleEventBase(event, {
						...options,
						isBackground: false,
					}),
				scheduleBackgroundEvent: (event, options) =>
					scheduleEventBase(event, {
						...options,
						isBackground: true,
					}),
			}}>
			{children}
		</TelegramLockContext.Provider>
	);
};

export const useTelegramLock = () => {
	const context = React.useContext(TelegramLockContext);
	if (!context) {
		throw new Error(
			'useTelegramLock must be used within a TelegramLockContext',
		);
	}
	return context;
};

function useLatestRef<T>(value: T): React.MutableRefObject<T> {
	const ref = React.useRef<T>(value);

	React.useEffect(() => {
		ref.current = value;
	}, [value]);

	return ref;
}

const cleanupTimeout = (timeout: NodeJS.Timeout | null) => {
	if (timeout) {
		clearTimeout(timeout);
	}
};
