import { useMutation } from '@tanstack/react-query';
import { axiosWithAuth, LegacyApiResponse } from '../../axios';

interface GetOtpPayload {
	telegramUserId: number;
}

interface GetOtpResult {
	otpCode: string;
}

const getOtp = async ({ telegramUserId }: GetOtpPayload) => {
	const { data } = await axiosWithAuth.post<LegacyApiResponse<GetOtpResult>>(
		'/auth/otp',
		{
			telegramUserId,
		},
	);

	return data.value;
};

const GET_OTP_MUTATION = 'GET_OTP_MUTATION';

function useGetOtp() {
	return useMutation<GetOtpResult, unknown, GetOtpPayload>({
		mutationKey: [GET_OTP_MUTATION],
		mutationFn: getOtp,
	});
}

export { getOtp, useGetOtp, GET_OTP_MUTATION };
