import { useQueryClient } from '@tanstack/react-query';
import { TelegramWindowContextType, useTelegram } from '../services';
import { Alerter } from '../utils';
import { fetchTgUser } from '../services/telegram/utils';
import { useState } from 'react';
import { ApiChat, ApiUser } from '../../../telegram-tt/src/api/types';
import { useTelegramLock } from '../services/telegram/lockContext';

export const useGetTeammateByUsername = (
	cb?: (data: {
		id?: string;
		firstName?: string;
		lastName?: string;
		username: string;
		avatar?: string;
	}) => void,
) => {
	const [isLoading, setIsLoading] = useState(false);

	const queryClient = useQueryClient();
	const tg = useTelegram();
	const lock = useTelegramLock()
	const onFetchFullUserInfo = async (
		chat: {
			chat: ApiChat;
			user: ApiUser | undefined;
		},
		tg: TelegramWindowContextType,
		username: string,
	) => {
		try {
			const privateChatUserInfo = await lock.scheduleEvent(tg =>
				fetchTgUser(queryClient, tg, +chat?.chat.id),
			);

			if (privateChatUserInfo?.userFullInfo) {
				const {
					profilePhoto: privateChatProfilePhoto,
					fallbackPhoto: privateChatFallbackPhoto,
				} = privateChatUserInfo.userFullInfo;
				const privateChatAvatar =
					privateChatProfilePhoto?.thumbnail?.dataUri ||
					privateChatFallbackPhoto?.thumbnail?.dataUri ||
					'';
				cb?.({
					id: chat.user?.id,
					firstName: chat.user?.firstName,
					lastName: chat.user?.lastName,
					username,
					avatar: privateChatAvatar,
				});
				return true;
			}
		} catch (error) {
			console.error('onFetchFullUserInfo error: ', error);
		}
	};

	const onAddHandle = async (username: string) => {
		try {
			setIsLoading(true);
			if (username) {
				const chat = await tg?.methods.proxy.getChatByUsername(
					username.replace('@', ''),
				);

				if (!chat) {
					Alerter.warning(
						`User with username ${username} not found. Please check spelling`,
					);
					return;
				}

				if (chat?.chat.id && tg?.custom) {
					const fullInfoHandled = await onFetchFullUserInfo(
						chat,
						tg,
						username,
					);
					if (fullInfoHandled) {
						return;
					}
				}

				cb?.({
					id: chat.user?.id,
					firstName: chat.user?.firstName,
					lastName: chat.user?.lastName,
					username,
				});
			}
		} catch (error) {
			console.log('onAddHandle: error: ', error);
		} finally {
			setIsLoading(false);
		}
	};

	return { onAddHandle, isLoading };
};
