import { useSelector } from 'react-redux';
import { RootStore } from './redux/createStore';
import { memo, useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from '@router';
import { useLocation } from 'react-router-dom';

function Redirects() {
	const navigate = useNavigate();
	const { pathname } = useLocation();

	const queryClient = useQueryClient();

	const {
		token: { accessToken },
		user: { workSpace },
	} = useSelector((state: RootStore) => state.auth);

	useEffect(() => {
		console.log('REDIRECTS', { accessToken });

		if (!accessToken) {
			navigate('/login', { replace: true });
		} else if (pathname === '/') {
			if (!workSpace) {
				navigate('/select-workspace', { replace: true });
			} else {
				navigate('/:workspaceId', {
					params: { workspaceId: workSpace.id.toString() },
				});
			}
		}
	}, [accessToken, queryClient, navigate, pathname, workSpace]);

	return null;
}

export default memo(Redirects);
