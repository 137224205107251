import { AuthWrapper } from '../../widgets';
import { MainLayout } from '../../components';

export const Catch = () => {
	return <div>Something went wrong... Caught at _app error boundary</div>;
};

export const Pending = () => <div>Loading from _app...</div>;

export default function Layout() {
	return (
		<AuthWrapper>
			<MainLayout />
		</AuthWrapper>
	);
}
