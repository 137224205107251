/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable indent */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { useTelegram } from '../../../services';
import {
	updateTelegramChatState,
	updateTelegramUserState,
} from '../../../redux/reducers/telegram-state.reducer';
import { RootStore } from '../../../redux/createStore';
import { TgUserData } from '../../../types';
import { checkForGroupChat } from '../../../utils';
import { useTelegramLock } from '../../../services/telegram/lockContext';
import { useLoadingProgress } from '../../../features/Dashboard/components/KanbanLoadingOverlay';
import { ApiChat, ApiChatFullInfo, ApiMessage, ApiUser, ApiUserFullInfo } from '../../../../../telegram-tt/src/api/types';

const CACHE_KEY_PREFIX = 'tg_chat_cache_';

type ChatCollectedData = {
	senderAvatar: string;
	chatAvatar: string;
	chatMembers: TgUserData[];
};

type ChatData = {
	chat: ApiChat | undefined;
	id: number;
	chatFullInfo: ApiChatFullInfo | undefined;
	msg: ApiMessage | undefined;
	lastMessageUserInfo: ApiUser | undefined;
	userFullInfo: ApiUserFullInfo | undefined;
} | null

export function useChat(id: string, isEnabled: boolean) {
	const tg = useTelegram();
	const lock = useTelegramLock();
	const dispatch = useDispatch();
	const { addLoadingChat, removeLoadingChat } = useLoadingProgress();

	const storeUsers = useSelector(
		(state: RootStore) => state.telegramState.users,
	);
	const { telegramUserId } = useSelector(
		(state: RootStore) => state.auth.user,
	);
	const isSynced = useSelector(
		(state: RootStore) => state.syncState.isSynced,
	);

	const formatChatData = (res: ChatData) => res ? ({
		id: res.id,
		isLoaded: !!res.chat,
		title: res.chat?.title,
		hasAccess:
			!!res.chat && !res.chat?.isNotJoined && !res.chat.isForbidden,
		type: res.chat?.type,
		accessHash: res.chat?.accessHash,
		unreadCount: res.chat?.unreadCount || 0,
		lastMessage: res.msg?.id
			? {
					id: res.msg.id,
					text: res.msg?.content.text?.text || null,
					content: res.msg?.content,
					date: res.msg.date,
				}
			: null,
		chatMembers: [],
		isRefetchDisabled: true,
		sender: res.lastMessageUserInfo?.id
			? {
					id: +res.lastMessageUserInfo.id,
					firstName: res.lastMessageUserInfo.firstName || '',
					lastName: res.lastMessageUserInfo.lastName || '',
					accessHash: res.lastMessageUserInfo.accessHash,
				}
			: null,
	}) : null;

	const getCachedChat = (chatId: string) => {
		try {
			const cached = localStorage.getItem(`${CACHE_KEY_PREFIX}${chatId}`);
			if (!cached) { throw new Error('Chat is not cached'); }
			const chatData =  JSON.parse(cached);
			dispatch(
				updateTelegramChatState({
					id: +chatId,
					data: chatData,
				}),
			);
			return chatData;
		} catch (e) {
			console.error('Error reading useChat cache:', e);
			localStorage.removeItem(`${CACHE_KEY_PREFIX}${chatId}`);
		}
		return null;
	};

	const setCachedChat = (chatId: string, data: unknown) => {
		try {
			localStorage.setItem(
				`${CACHE_KEY_PREFIX}${chatId}`,
				JSON.stringify({
					state: {
						data,
						dataUpdatedAt: Date.now(),
					},
				}),
			);
		} catch (e) {
			console.error('Error writing useChat cache:', e);
		}
	};

	const updateStoreUser = (id: number, data: TgUserData) =>
		dispatch(updateTelegramUserState({ id, data }));

	const getChatData = async (chatId: string) => {
		const isGroupChat = checkForGroupChat(chatId);

		const res = await lock.scheduleBackgroundEvent(async tg => {
			if (!tg?.custom?.proxy) {
				console.warn('TG proxy not available for chat:', chatId);
				return null;
			}
			return tg.custom.proxy.getChatWithLastMessageById(+chatId);
		});

		if (!res?.chat) {
			throw new Error('not found chat');
		}

		const collectedData: ChatCollectedData = {
			senderAvatar: '',
			chatAvatar: '',
			chatMembers: [],
		};

		if (!isGroupChat && res.msg?.senderId === chatId && res.chat) {
			const privateChatUsersData = [
				{
					id: res.id,
					username: res.chat.usernames?.[0]?.username || null,
				},
				{
					id: telegramUserId,
					username: storeUsers[`${telegramUserId}`]?.username || null,
				},
			];
			privateChatUsersData.map(user => {
				if (!user.id) {
					return;
				}

				updateStoreUser(user.id, user);
				collectedData.chatMembers = [
					...collectedData.chatMembers,
					user,
				];
			});
		}

		if (
			!isGroupChat &&
			res.msg?.senderId !== chatId &&
			res.lastMessageUserInfo &&
			telegramUserId
		) {
			const userData = {
				id: telegramUserId,
				username:
					res.lastMessageUserInfo.usernames?.[0]?.username || null,
			};
			updateStoreUser(telegramUserId, userData);
			collectedData.chatMembers = [
				...collectedData.chatMembers,
				userData,
			];
		}

		const neededData = formatChatData(res);

		if (res.lastMessageUserInfo && neededData) {
			dispatch(
				updateTelegramChatState({
					id: +chatId,
					data: neededData,
				}),
			);
		} else {
			throw new Error('no last message info');
		}

		return neededData;
	};

	React.useEffect(() => {
		const cacheUpdatedAt = getCachedChat(id)?.state?.dataUpdatedAt;
		const isFresh =  cacheUpdatedAt ? Math.abs(Date.now() - cacheUpdatedAt) / 36e5 : false;

		if (isEnabled && isSynced && !isFresh) {
			addLoadingChat(+id);
		}
		return () => {
			removeLoadingChat(+id);
		};
	}, [id, isEnabled, isSynced]);

	const { data, isLoading, error } = useQuery({
		queryKey: [`tg1-chat-${+id}`],
		queryFn: async () => {
			const cachedData = getCachedChat(id)?.state?.data;
			if (cachedData) {
				dispatch(
					updateTelegramChatState({
						id: +id,
						data: cachedData,
					}));
				removeLoadingChat(+id);
				return cachedData;
			}
			const result = await getChatData(id);
			setCachedChat(id, { ...result, dataUpdatedAt: Date.now() });
			removeLoadingChat(+id);
			return result;
		},
		retryDelay: 1000,
		retry(failureCount) {
			if (failureCount > 1) {
				const chatState = {
					id: +id,
					data: {
						id: +id,
						isLoaded: true,
						isChatFullyLoaded: true,
						isRefetchDisabled: true,
					},
				};
				dispatch(updateTelegramChatState(chatState));
				setCachedChat(id, {
					...chatState.data,
					dataUpdatedAt: Date.now(),
				});

				removeLoadingChat(+id);
				return false;
			}
			return true;
		},
		staleTime: 1000 * 60 * 60 * 24, 
		gcTime: 1000 * 60 * 60 * 24, 
		initialData: () => getCachedChat(id)?.state?.data,
		initialDataUpdatedAt: () => getCachedChat(id)?.state?.dataUpdatedAt,
		enabled: isEnabled && isSynced,
	});

	return { data, isLoading, error };
}
