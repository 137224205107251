import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OnboardingSteps } from '../../types/onboarding.types';

const initialState = {
	isOpen: false,
	activeStep: OnboardingSteps.SELECT_WORKSPACE,
	inviteMembersModalOpen: false,
	syncSettingsModalOpen: false,
	createWorkspaceModalOpen: false,
	onboardingCanBeClosed: false,
	isOnboardingInProgress: false,
};

const onboardingReducer = createSlice({
	name: 'onboarding',
	initialState,
	reducers: {
		setOnboardingVisibility: (state, action: PayloadAction<boolean>) => {
			if (action.payload === true && state.isOnboardingInProgress) {
				return state;
			}
			state.isOpen = action.payload;
			if (action.payload === true) {
				state.isOnboardingInProgress = true;
			}
		},
		setOnboardingActiveStep: (
			state,
			action: PayloadAction<OnboardingSteps>,
		) => {
			state.activeStep = action.payload;
		},
		setInviteMembersModalOpen: (state, action: PayloadAction<boolean>) => {
			state.inviteMembersModalOpen = action.payload;
		},
		setSyncSettingsModalOpen: (state, action: PayloadAction<boolean>) => {
			state.syncSettingsModalOpen = action.payload;
		},
		setCreateWorkspaceModalOpen: (
			state,
			action: PayloadAction<boolean>,
		) => {
			state.createWorkspaceModalOpen = action.payload;
		},
		setOnboardingCanBeClosed: (state, action: PayloadAction<boolean>) => {
			state.onboardingCanBeClosed = action.payload;
		},
		resetOnboardingState: () => {
			return {
				...initialState,
				isOnboardingInProgress: false,
			};
		},
	},
});

export const {
	setOnboardingActiveStep,
	setOnboardingVisibility,
	setInviteMembersModalOpen,
	setSyncSettingsModalOpen,
	setCreateWorkspaceModalOpen,
	setOnboardingCanBeClosed,
	resetOnboardingState,
} = onboardingReducer.actions;

export default onboardingReducer.reducer;
