import { Box, Flex, HStack, Progress, Text } from '@chakra-ui/react';
import { atom, useAtom } from 'jotai';
import { Icon } from '../../../components/Icon/Icon';

import css from './KanbanLoadingOverlay.module.css';

const loadingChatsAtom = atom<Set<number>>(new Set<number>());
const totalChatsAtom = atom<number>(0);

export const useLoadingProgress = () => {
	const [loadingChats, setLoadingChats] = useAtom(loadingChatsAtom);
	const [totalChats, setTotalChats] = useAtom(totalChatsAtom);

	return {
		loadingChats,
		totalChats,
		addLoadingChat: (chatId: number) =>
			setLoadingChats(prev => new Set(prev).add(chatId)),
		removeLoadingChat: (chatId: number) =>
			setLoadingChats(prev => {
				const newSet = new Set(prev);
				newSet.delete(chatId);
				return newSet;
			}),
		resetState: () => {
			setLoadingChats(new Set());
			setTotalChats(0);
		},
		setTotal: (total: number) => setTotalChats(total),
		loadedCount: totalChats - loadingChats.size,
	};
};

export const KanbanLoadingOverlay = () => {
	const { loadingChats, totalChats, loadedCount } = useLoadingProgress();

	if (totalChats === 0) {
		return null;
	}
	if (loadedCount === totalChats && loadingChats.size === 0) {
		return null;
	}

	const progress = Math.max(
		0,
		totalChats ? Math.floor((loadedCount / totalChats) * 100) : 0,
	);

	return (
		<Box id="overlay-modal" className={css.overlay} bg="blackAlpha.400">
			<Flex
				bg="white"
				p={'3rem 2.5rem'}
				borderRadius="xl"
				flexDirection={'column'}
				alignItems={'center'}
				shadow="2xl"
				maxW="25rem"
				w="100%">
				{/* Title Section */}
				<Text fontSize="xl" fontWeight="600" mb={'1rem'}>
					Preparing your Workspace
				</Text>

				<Text
					fontSize="sm"
					color="gray.600"
					textAlign="center"
					mb={'2rem'}>
					Syncing chats with Telegram...
				</Text>

				{/* Progress Section */}
				<Progress
					className={css.progress}
					min={0}
					value={progress}
					isAnimated={true}
				/>

				<HStack w={'100%'} justify="space-between" mb={'2rem'}>
					<Text fontSize="sm">{Math.max(0, progress)}% complete</Text>
					<Text fontSize="sm" color="secondary">
						{Math.max(0, loadedCount)}/{totalChats} chats
					</Text>
				</HStack>

				{/* Warning Box */}
				<Flex
					p={'0.75rem 0.5rem'}
					border={'1px solid #EBEBEB'}
					borderRadius={'0.5rem'}
					alignItems={'center'}
					gap={'0.25rem'}
					mb={'1rem'}>
					<Icon
						name="info-circle"
						width="1rem"
						height="1rem"
						color={'#666666'}
					/>
					<Text fontSize="0.75rem" color="#666666">
						Please don't close this tab.
					</Text>
				</Flex>

				{/* Loading Message */}
				<Text fontSize="0.75rem" color="#999999">
					This may take a few moments...
				</Text>
			</Flex>
		</Box>
	);
};
