/* eslint-disable indent */
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useQueryClient } from '@tanstack/react-query';
import { RootStore } from '../../../redux/createStore';
import { useTelegram } from '../../../services';
import {
	ApiUpdateChatInbox,
	ApiUpdateChat,
	ApiMessage,
} from '../../../../../telegram-tt/src/api/types';
import { updateTelegramChatState } from '../../../redux/reducers/telegram-state.reducer';
import { fetchUserById } from '../../../services/telegram/utils';
import { useTelegramLock } from '../../../services/telegram/lockContext';

const ACTION_USER_LEFT_CHAT = ['Notification.Kicked', 'Notification.LeftChat'];

export const useSubscribeToEvents = () => {
	const tg = useTelegram();
	const lock = useTelegramLock()
	const queryClient = useQueryClient();
	const dispatch = useDispatch();
	const chatsCache = useSelector(
		(state: RootStore) => state.telegramState.chats,
	);

	const { telegramUserId: currentUserTelegramId } = useSelector(
		(state: RootStore) => state.auth.user,
	);

	const updateUnread = (data: ApiUpdateChatInbox | ApiUpdateChat) => {
		if (data.id) {
			dispatch(
				updateTelegramChatState({
					id: +data.id,
					data: { unreadCount: data.chat.unreadCount || 0 },
				}),
			);
		}
	};

	const resetChatCacheData = (id: number) =>
		dispatch(
			updateTelegramChatState({
				id,
				data: {
					id,
					isLoaded: true,
					hasAccess: false,
					unreadCount: 0,
					lastMessage: null,
					chatAvatarDataUri: '',
					sender: null,
					chatMembers: [],
					isChatFullyLoaded: true,
					isRefetchDisabled: true,
				},
			}),
		);

	const updateChatLastMessage = async (newMessage: Partial<ApiMessage>) => {
		try {
			if (
				!tg ||
				!newMessage.senderId ||
				!newMessage?.chatId ||
				!chatsCache[newMessage.chatId]
			) {
				return;
			}

			const senderData = await lock.scheduleEvent(tg =>
				fetchUserById(queryClient, tg, +newMessage.senderId!),
			);

			dispatch(
				updateTelegramChatState({
					id: +newMessage.chatId,
					data: {
						unreadCount: newMessage.isOutgoing
							? 0
							: chatsCache[newMessage.chatId].unreadCount + 1,
						lastMessage: newMessage.id
							? {
									id: newMessage.id,
									text:
										newMessage.content?.text?.text || null,
									content: newMessage.content,
									date: newMessage.date,
								}
							: null,
						sender: senderData?.userShortInfo?.id
							? {
									id: +senderData.userShortInfo.id,
									firstName:
										senderData.userShortInfo.firstName ||
										'',
									lastName:
										senderData.userShortInfo.lastName || '',
									accessHash:
										senderData.userShortInfo.accessHash,
								}
							: null,
					},
				}),
			);
		} catch (error) {
			console.log('updateChatLastMessage error', error);
		}
	};

	useEffect(() => {
		const newMessageEvents = tg?.events.subscribe('newMessage', msg => {
			const chatToUpdate = msg?.chatId && chatsCache[+msg.chatId];

			// Return if no chat is found with the given chatId
			if (!chatToUpdate) {
				return;
			}

			const isCurrentUserLeftChat =
				msg.content?.action?.targetUserIds?.includes(
					currentUserTelegramId.toString(),
				) &&
				ACTION_USER_LEFT_CHAT.includes(msg.content?.action?.text || '');

			if (isCurrentUserLeftChat) {
				resetChatCacheData(chatToUpdate.id);
				return;
			}
			updateChatLastMessage(msg);
		});
		const chatInboxEvents = tg?.events.subscribe(
			'updateChatInbox',
			chatData => {
				// console.log('---- NEW CHAT_INBOX UPDATE ----', chatData);

				const chatToUpdate = chatData?.id && chatsCache[+chatData.id];

				if (!chatToUpdate) {
					return;
				}

				updateUnread(chatData);
			},
		);

		const updateChatEvents = tg?.events.subscribe(
			'updateChat',
			chatData => {
				const chatToUpdate = chatData?.id && chatsCache[+chatData.id];

				if (!chatToUpdate) {
					return;
				}

				if (chatData.chat?.isNotJoined) {
					resetChatCacheData(+chatData.id);
				}

				const unreadCountKey = 'unreadCount' in chatData.chat;

				if (!unreadCountKey) {
					return;
				}

				updateUnread(chatData);
			},
		);

		const updateChatMembersEvents = tg?.events.subscribe(
			'updateChatMembers',
			data => {
				const chatToUpdate = data?.id && chatsCache[+data.id];

				const isChatMembersUpdateType =
					data['@type'] === 'updateChatMembers';

				if (!chatToUpdate || !data?.id || !isChatMembersUpdateType) {
					return;
				}

				queryClient.refetchQueries({
					queryKey: [`tg1-chat-${+data.id}`],
				});
			},
		);

		return () => {
			newMessageEvents?.();
			chatInboxEvents?.();
			updateChatEvents?.();
			updateChatMembersEvents?.();
		};
	}, [tg?.events, chatsCache]);
};
