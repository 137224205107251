import { useMutation } from '@tanstack/react-query';
import { ApiResponseEmpty } from '../../../../../types';
import API from '../../../../../api/api.base';
import { queryClient, QueryKeys } from '../../../../../constants';
import { Alerter } from '../../../../../utils';
import { useGetWorkspaceId } from '../../../../../hooks';

export const useCreateTemplate = () => {
	const workspaceId = useGetWorkspaceId();

	return useMutation<ApiResponseEmpty, unknown, string>({
		mutationKey: [QueryKeys.CREATE_TEMPLATE],
		mutationFn: payload =>
			API.post(`/api/workspaces/${workspaceId}/templates/create`, {
				content: payload,
			}),
		onSuccess(data) {
			if (!data.success) {
				Alerter.error(data.errors?.[0]?.message);
				return;
			}

			queryClient().invalidateQueries({
				queryKey: [QueryKeys.GET_TEMPLATES, workspaceId],
			});
		},
	});
};
