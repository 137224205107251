import React, { PropsWithChildren } from 'react';
import { HStack, StackProps } from '@chakra-ui/react';
import { colors } from '../../../theme/colors';
import { Icon } from '../../../components';

interface IWarningBannerWrapperProps extends PropsWithChildren {
	wrapperProps?: StackProps;
	iconName: string;
}

export const WarningBannerWrapper: React.FC<IWarningBannerWrapperProps> = ({
	wrapperProps,
	children,
	iconName,
}) => {
	return (
		<HStack
			p={2}
			spacing={1}
			align="flex-start"
			borderRadius="8px"
			border="1px solid"
			borderColor="gray.20"
			bg="gray.10"
			{...wrapperProps}>
			<Icon
				name={iconName}
				width="16px"
				height="16px"
				style={{ flexShrink: 0 }}
				color={colors.tertiary}
			/>
			{children}
		</HStack>
	);
};
