import React, { useCallback, useEffect, useState } from 'react';
import { Button, Flex, Text, VStack } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useQueryClient } from '@tanstack/react-query';
import { ChatItem } from './ChatItem';
import { useSetTeamChat } from '../queries';
import { EmptyItemsText, Loader } from '../../../components';
import { TelegramWindowContextType, useTelegram } from '../../../services';
import { RootStore } from '../../../redux/createStore';
import { useGetMatchedWorkspaceId } from '../../../hooks';
import { ApiChatType } from '../../../types';
import { fetchChats } from '../../../services/telegram/utils';
import { useTelegramLock } from '../../../services/telegram/lockContext';

type ExtractedChatData = {
	id: string;
	title: string;
	type: ApiChatType;
	accessHash?: string;
};

interface ModalBodyProps {
	onClose: VoidFunction;
}

export const ModalBody: React.FC<ModalBodyProps> = ({ onClose }) => {
	const tg = useTelegram();
	const lock = useTelegramLock();
	const queryClient = useQueryClient();
	const workspaceId = useGetMatchedWorkspaceId();
	const { mutateAsync, isPending } = useSetTeamChat();

	const { isSynced } = useSelector((state: RootStore) => state.syncState);

	const [isLoaded, setIsLoaded] = useState(false);
	const [selectedChat, setSelectedChat] = useState<{
		id: string;
		title: string;
	} | null>(null);
	const [chats, setChats] = useState<ExtractedChatData[]>([]);

	const getTgChats = useCallback(
		async (tg: TelegramWindowContextType) => {
			try {
				setIsLoaded(false);
				const res = await fetchChats(queryClient, tg, 25, workspaceId);

				if (!res || !res?.chats?.length) {
					return;
				}

				const fetchedChats = res.chats.map(chat => ({
					id: chat.id,
					title: chat.title,
					type: chat.type,
					accessHash: chat.accessHash,
				}));

				setChats(fetchedChats);
			} catch (error) {
				console.log('getTgChats error: ', error);
			} finally {
				setIsLoaded(true);
			}
		},
		[queryClient, workspaceId],
	);
	useEffect(() => {
		if (tg && isSynced) {
			lock.scheduleBackgroundEvent(tg => getTgChats(tg));
		}
	}, [tg, isSynced, getTgChats]);

	const onChangeSelectedFolders = (id: string, title: string) => {
		if (id === selectedChat?.id) {
			setSelectedChat(null);
			return;
		}
		setSelectedChat({ id, title });
	};

	const onSubmit = async (
		workspaceId: number,
		telegramChatId: number,
		name: string,
	) => {
		try {
			const res = await mutateAsync({
				workspaceId,
				telegramChatId,
				name,
			});

			if (!res.success) {
				return;
			}
			setSelectedChat(null);
			onClose();
		} catch (error) {
			console.log('onSubmit team chat error: ', error);
		}
	};

	return (
		<Flex flexDirection="column" align="start" gap="30px">
			<VStack w="100%" spacing={3}>
				<Text
					fontWeight={600}
					fontSize="20px"
					lineHeight="24px"
					textAlign="center">
					Select your team chat
				</Text>
				<Text textAlign="center" color="secondary">
					Most recent 25 chats are shown
				</Text>
			</VStack>

			{isLoaded ? (
				<React.Fragment>
					<Flex
						flexDirection="column"
						align="start"
						borderRadius="8px"
						borderWidth="1px"
						borderColor="gray.20"
						bg="gray.10"
						w="100%"
						maxH="400px"
						overflowY="auto">
						{chats?.length ? (
							chats.map(chat => (
								<ChatItem
									key={chat.id}
									id={chat.id}
									name={chat.title}
									chatType={chat.type}
									accessHash={chat.accessHash}
									isChecked={chat.id === selectedChat?.id}
									onCheckedChange={() =>
										onChangeSelectedFolders(
											chat.id,
											chat.title,
										)
									}
								/>
							))
						) : (
							<EmptyItemsText
								text="Chats are not found"
								wrapperProps={{ width: 'full', border: 'none' }}
							/>
						)}
					</Flex>

					<VStack w="100%" spacing={3}>
						<Button
							w="full"
							maxW="200px"
							variant="outline"
							alignSelf="center"
							onClick={() => {
								if (!workspaceId || !selectedChat) {
									return;
								}

								onSubmit(
									workspaceId,
									+selectedChat.id,
									selectedChat.title,
								);
							}}
							boxShadow="0px 2px 4px 0px #0000000A, 0px 0px 4px 0px #00000014"
							isDisabled={!selectedChat}
							isLoading={isPending}>
							Sync with DISE
						</Button>

						<Button
							onClick={onClose}
							w="65%"
							variant="ghost"
							alignSelf="center">
							Continue without sync
						</Button>
					</VStack>
				</React.Fragment>
			) : (
				<Loader
					centerProps={{
						h: '500px',
						justifyContent: 'center',
						w: '100%',
					}}
				/>
			)}
		</Flex>
	);
};
