import React from 'react';
import { Box, VStack, BoxProps } from '@chakra-ui/react';
import {
	CardBody,
	CardFooter,
	CardHeader,
	LimitedAccessView,
} from '../TaskCard/components';
import { InternalChatMessagePreview } from '../../features/Dashboard/types';

interface IDecoyTaskCardProps {
	wrapperProps?: BoxProps;
	cardName: string;
	hasAccess?: boolean;
	companyChatData?: InternalChatMessagePreview | null;
	currentUserTagged?: boolean;
	tgSenderName: string;
	tgMessageText: string;
	tgUnreadCount?: number;
	cardId: number;
}

export const DecoyTaskCard: React.FC<IDecoyTaskCardProps> = ({
	cardName,
	companyChatData,
	wrapperProps,
	currentUserTagged,
	tgSenderName,
	tgMessageText,
	tgUnreadCount,
	cardId,
}) => {
	return (
		<Box
			w="100%"
			borderRadius="4px"
			boxShadow="light"
			borderWidth="1px"
			borderColor="gray.20"
			role="button"
			bg={'gray.10'}
			{...wrapperProps}>
			<VStack
				spacing={0}
				align="flex-start"
				p="8px 8px 4px 8px"
				borderBottomWidth="1px"
				borderBottomColor="transparentLight.5">
				<CardHeader
					title={cardName}
					logoDataUri={''}
					isLoaded={true}
					chatId={cardId}
					cardId={cardId}
					lastTgChatMessageDate={null}
					workspaceId={0}
				/>

				<CardBody
					hasAccess={true}
					unreadCount={tgUnreadCount}
					message={{
						id: 1,
						text: tgMessageText,
						content: { text: { text: tgMessageText } },
						date: undefined,
					}}
					name={tgSenderName}
					avatarUri={undefined}
					isLoaded={true}
					chatId={cardId}
				/>
				<LimitedAccessView hasAccess={true} isLoaded={true} />
			</VStack>
			{companyChatData ? (
				<CardFooter
					isLoaded={true}
					avatarUri={undefined}
					currentUserTagged={currentUserTagged}
					{...companyChatData}
				/>
			) : null}
		</Box>
	);
};
