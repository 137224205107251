import { useMutation } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';
import API from '../api/api.base';
import { ApiResponseEmpty } from '../types';
import { logout } from '../redux/reducers/auth.reducer';
import { setTelegramConnected } from '../redux/reducers/login-flow.reducer';
import { QueryKeys } from '../constants';
import { clearTgDataCache } from '../redux/reducers/telegram-state.reducer';
import { setOnboardingVisibility } from '../redux/reducers/onboarding.reducer';
import { useGetMatchedWorkspaceId } from '../hooks/useGetWorkspaceId';
import { invoke, SignalRMethods } from '../services';
import { RootStore } from '../redux/createStore';
import { useNavigate } from '@router';

export const useLogout = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const workspaceId = useGetMatchedWorkspaceId();
	const { signalRConnected } = useSelector(
		(state: RootStore) => state.signalR,
	);

	return useMutation<
		ApiResponseEmpty,
		unknown,
		{ accessToken: string; refreshToken: string }
	>({
		mutationKey: [QueryKeys.LOGOUT],
		mutationFn: payload => API.post('/api/auth/logout', payload),
		onSettled: async () => {
			// if (!data.success) {
			// 	return;
			// }
			if (workspaceId && signalRConnected) {
				await invoke(SignalRMethods.WorkspaceLeaveChat, workspaceId);
			}
			dispatch(logout());
			dispatch(setTelegramConnected({ isConnected: false }));
			dispatch(clearTgDataCache());
			dispatch(setOnboardingVisibility(false));
			navigate('/login');
		},
	});
};
