import React, { memo, useMemo, useState, useRef, useEffect } from 'react';
import { Box, VStack, BoxProps } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import {
	CardBody,
	CardFooter,
	CardHeader,
	CardLabels,
	LimitedAccessView,
} from './components';
import { InternalChatMessagePreview } from '../../features/Dashboard/types';
import { RootStore } from '../../redux/createStore';
import {
	CardDefaultStatusEnum,
	LabelItemType,
	TeamChatData,
} from '../../types';
import { getActivitySubjectName } from '../../utils';
import { useGetMatchedWorkspaceId } from '../../hooks';
import { useChat } from './hooks';
import { useAtom } from 'jotai';
import { moveTaskToEnd, tasksAtom } from '@atoms/tasksAtom';
import { useUpdateBoardCardData } from '../../features/Dashboard/hooks';
import { useUpdateWorkspaceCardStatus } from '@queries/useUpdateWorkspaceCardStatus';
import { useGetWorkspaceCardsStatuses } from '@queries/useGetWorkspaceCardsStatuses';
import buildClassName from '../../../../telegram-tt/src/util/buildClassName';

interface ITaskCardSoloProps {
	wrapperProps?: BoxProps;
	isDragging: boolean;
	onRedirect?: VoidFunction;
	cardName: string;
	hasAccess?: boolean;
	chatTelegramId: number;
	companyChatData?: InternalChatMessagePreview | null;
	teamChatData?: TeamChatData | null;
	currentUserTagged?: boolean;
	isTeamChat?: boolean;
	trackedLastTelegramMessageUnixTime?: number | null;
	cardId: number;
	cardStatusId?: number;
	teamTgIds?: number[];
	labels?: LabelItemType[];
}

const TaskCardSoloComponent: React.FC<ITaskCardSoloProps> = ({
	isDragging,
	cardName,
	onRedirect,
	chatTelegramId,
	companyChatData,
	wrapperProps,
	currentUserTagged,
	isTeamChat = false,
	trackedLastTelegramMessageUnixTime,
	cardId,
	cardStatusId,
	teamTgIds,
	labels,
}) => {
	const workspaceId = useGetMatchedWorkspaceId();
	const [tasks, setTasks] = useAtom(tasksAtom);

	const { data: statusesData } = useGetWorkspaceCardsStatuses();
	const { mutateAsync: updateWorkspaceCardStatus } =
		useUpdateWorkspaceCardStatus();
	const { updateBoardCardData } = useUpdateBoardCardData();

	const avatars = useSelector((state: RootStore) => state.avatars);
	const cachedData = useSelector(
		(state: RootStore) => state.telegramState.chats[chatTelegramId],
	);

	useChat(chatTelegramId.toString(), !cachedData?.isRefetchDisabled);

	const userName = cachedData?.sender
		? getActivitySubjectName(cachedData.sender)
		: '';

	const isLoaded = cachedData?.isLoaded;

	// Move drag-sensitive UI state to refs
	const isDraggingRef = useRef(isDragging);

	// Update ref when isDragging prop changes
	useEffect(() => {
		isDraggingRef.current = isDragging;
	}, [isDragging]);

	// Track visibility state in a ref to avoid rerenders
	const visibilityRef = useRef({
		showTrackBtn: false,
		showTrashBtn: false,
	});

	// Update visibility logic without causing rerenders
	useEffect(() => {
		const isLastMessageFromTeam = teamTgIds?.includes(
			cachedData?.sender?.id || 0,
		);

		visibilityRef.current = {
			showTrackBtn:
				!isTeamChat &&
				cachedData?.hasAccess &&
				!isLastMessageFromTeam &&
				cardStatusId !== CardDefaultStatusEnum.ARCHIVE,

			showTrashBtn:
				!isTeamChat &&
				cachedData?.hasAccess &&
				cardStatusId !== CardDefaultStatusEnum.ARCHIVE,
		};
	}, [
		isTeamChat,
		cachedData?.hasAccess,
		cardStatusId,
		cachedData?.sender?.id,
		teamTgIds,
	]);

	// Use CSS classes for visibility
	const trackBtnClassName = useMemo(() => {
		return buildClassName(
			'card__track-status-btn',
			!visibilityRef.current.showTrackBtn && 'hidden',
		);
	}, []); // No dependencies needed since we use ref

	// console.info('render');

	const [isArchiveLoading, setIsArchiveLoading] = useState(false);
	const handleArchiveClick = async () => {
		if (cardStatusId === CardDefaultStatusEnum.ARCHIVE || !cardStatusId) {
			return;
		}
		const getStatusId = (targetStatusId: number) => {
			return (
				statusesData?.value?.statuses.find(
					x => x.typeId === targetStatusId,
				)?.id + ''
			);
		};
		const destinationStatusId = getStatusId(CardDefaultStatusEnum.ARCHIVE);
		const sourceStatusId = getStatusId(cardStatusId);
		
		const sourceIndex = tasks[sourceStatusId + ''].findIndex(x => x.cardId === cardId);
		
		setIsArchiveLoading(true);
		const res = await updateWorkspaceCardStatus({
			workspaceId,
			cardId,
			statusId: +destinationStatusId,
		});

		if (!res.success) {
			setTasks(tasks);
			setIsArchiveLoading(false);
			return;
		}
		setTasks(
			moveTaskToEnd({
				tasks,
				sourceStatusId,
				sourceIndex: +sourceIndex,
				destinationStatusId,
			}),
		);
		updateBoardCardData(cardId, +destinationStatusId);
		setIsArchiveLoading(false);
	};

	const className = useMemo(() => {
		return buildClassName('task-card', isDragging && 'task-card--dragging');
	}, [isDragging]);

	return (
		<Box
			className={className}
			w="100%"
			borderRadius="4px"
			boxShadow="light"
			borderWidth="1px"
			borderColor="gray.20"
			bg={isDragging ? 'gray.20' : 'gray.10'}
			role="button"
			onClick={onRedirect}
			{...wrapperProps}>
			<VStack
				spacing={0}
				align="flex-start"
				p="8px 8px 4px 8px"
				borderBottomWidth="1px"
				borderBottomColor="transparentLight.5">
				<CardHeader
					onArchiveClick={handleArchiveClick}
					title={cardName}
					isLoaded={isLoaded}
					chatTelegramId={chatTelegramId}
					cardId={cardId}
					lastTgChatMessageDate={
						cachedData?.lastMessage?.date || null
					}
					trackedLastTelegramMessageUnixTime={
						trackedLastTelegramMessageUnixTime
					}
					workspaceId={workspaceId}
					cachedData={cachedData}
					isArchiveLoading={isArchiveLoading}
				/>
				<CardLabels isLoaded={isLoaded} data={labels || []} />

				<CardBody
					hasAccess={cachedData?.hasAccess}
					unreadCount={cachedData?.unreadCount}
					message={cachedData?.lastMessage || null}
					name={userName}
					storedAvatar={avatars[`${cachedData?.sender?.id || 0}`]}
					isLoaded={isLoaded}
					chatId={cachedData?.sender?.id || 0}
					chatTelegramId={chatTelegramId}
					senderData={cachedData?.sender}
					isEnableFetchSenderAvatar={
						!!cachedData?.isLoaded &&
						!teamTgIds?.includes(cachedData?.sender?.id || 0) &&
						!!cachedData?.sender?.id &&
						!!cachedData?.hasAccess
					}
				/>
				
				<LimitedAccessView
					hasAccess={cachedData?.hasAccess}
					isLoaded={isLoaded}
				/>
			</VStack>
			
			{companyChatData ? (
				<CardFooter
					isLoaded={!!cachedData}
					avatar={avatars[`${companyChatData.telegramUserId}`]}
					currentUserTagged={currentUserTagged}
					{...companyChatData}
				/>
			) : null}
		</Box>
	);
};

export { TaskCardSoloComponent as TaskCardSolo };

// export const TaskCardSolo = memo(TaskCardSoloComponent);

// TaskCardSolo.displayName = 'TaskCardSolo';
