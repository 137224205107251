import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	Box,
	Button,
	HStack,
	Text,
	useDisclosure,
	VStack,
} from '@chakra-ui/react';
import {
	CustomTag,
	Icon,
	Loader,
	Popover,
	PopoverContent,
	PopoverTrigger,
} from '../../../../components';
import { getLabelInfoByType } from '../../../../utils';
import { useGetWorkspaceLabels } from '../../../../queries';
import { colors } from '../../../../theme/colors';
import { useGetWorkspaceId } from '../../../../hooks';
import { RootStore } from '../../../../redux/createStore';
import { setActiveLabelId } from '../../../../redux/reducers/dashboard-state.reducer';

import styles from './LabelsSelectPopup.module.css';

export const LabelsSelectPopup: React.FC = () => {
	const dispatch = useDispatch();
	const workspaceId = useGetWorkspaceId();
	const { isOpen, onToggle, onClose } = useDisclosure();

	const { data, isLoading } = useGetWorkspaceLabels(workspaceId);

	const activeLabelId = useSelector(
		(state: RootStore) => state.dashboardState.activeLabelId,
	);

	const onSetActiveLabelId = (id: number) => dispatch(setActiveLabelId(id));

	const filteredLabelItems = useMemo(
		() =>
			data?.value?.workspaceLabels?.filter(
				el => el.workspaceLabelId !== activeLabelId,
			) || [],
		[data?.value?.workspaceLabels, activeLabelId],
	);

	const selectedLabelData = useMemo(() => {
		const matchedLabel = data?.value?.workspaceLabels.find(
			el => el.workspaceLabelId === activeLabelId,
		);
		if (!matchedLabel) {
			return;
		}
		const labelData = getLabelInfoByType(matchedLabel.colourId);
		return {
			bgColor: labelData.bgColor,
			textColor: labelData.textColor,
			id: matchedLabel.workspaceLabelId,
			name: matchedLabel.name,
		};
	}, [activeLabelId, data?.value?.workspaceLabels]);

	const isActiveState = useMemo(
		() => isOpen || !!selectedLabelData,
		[isOpen, selectedLabelData],
	);

	const renderContent = filteredLabelItems.map(el => {
		const labelData = getLabelInfoByType(el.colourId);
		return (
			<Button
				key={el.workspaceLabelId}
				variant="unstyled"
				display="flex"
				h="32px"
				maxW="100%"
				w="full"
				p="6px 4px"
				borderRadius="4px"
				justifyContent="flex-start"
				whiteSpace="normal"
				onClick={() => {
					onSetActiveLabelId(el.workspaceLabelId);
					onClose();
				}}
				_focusVisible={{
					boxShadow: 'unset',
				}}
				_hover={{
					bg: 'transparentLight.10',
					textAlign: 'left',
				}}>
				<CustomTag
					label={el.name}
					bgColor={labelData.bgColor}
					labelColor={labelData.textColor}
					wrapperProps={{
						maxW: '100%',
						textAlign: 'left',
					}}
				/>
			</Button>
		);
	});

	return (
		<Popover open={isOpen} onOpenChange={onToggle}>
			<PopoverTrigger asChild={true}>
				<HStack
					spacing={1}
					p="5px 8px"
					align="center"
					borderRadius="4px"
					cursor="pointer"
					minW="fit-content"
					bg={
						selectedLabelData
							? 'gray.20'
							: isOpen
								? 'gray.15'
								: 'transparent'
					}
					transition="0.2s background ease">
					<Box boxSize="16px">
						<Icon
							name="label"
							height="16"
							width="16"
							color={
								isActiveState
									? colors.primary
									: colors.secondary
							}
						/>
					</Box>
					<Text
						variant="bodyLarge"
						fontWeight={500}
						color={isActiveState ? 'primary' : 'secondary'}>
						Label
					</Text>
					{selectedLabelData ? (
						<CustomTag
							label={selectedLabelData.name}
							bgColor={selectedLabelData.bgColor}
							labelColor={selectedLabelData.textColor}
							enableCloseButton={true}
							wrapperProps={{
								pr: '3px',
								maxW: '160px',
							}}
							closeButtonProps={{
								'aria-label': 'clear labels filter',
								onClick: ev => {
									ev.stopPropagation();
									onSetActiveLabelId(0);
								},
							}}
							closeIconColor={selectedLabelData.textColor}
						/>
					) : null}
				</HStack>
			</PopoverTrigger>
			<PopoverContent
				sideOffset={5}
				align="start"
				className={styles.LabelsSelectPopoverContent}>
				<Box maxH="300px" p="4px" w="full" overflow="clip">
					{isLoading ? (
						<Loader
							centerHeight="100px"
							spinnerSize="md"
							spinnerProps={{
								thickness: '3px',
							}}
						/>
					) : filteredLabelItems?.length ? (
						<VStack
							align="flex-start"
							spacing="2px"
							h="100%"
							maxH="280px"
							overflowY="auto"
							sx={{
								scrollbarWidth: 'thin',
							}}>
							{renderContent}
						</VStack>
					) : (
						<VStack h="100px" justify="center">
							<Text color="secondary" textAlign="center">
								No labels{' '}
								{data?.value?.workspaceLabels?.length
									? 'anymore'
									: 'yet'}
							</Text>
						</VStack>
					)}
				</Box>
			</PopoverContent>
		</Popover>
	);
};
