import SettingsPage from '../../../features/Settings/Settings.page';

export const Loader = () => 'Route loader';

export const Action = () => 'Route action';

export const Catch = () => <div>Something went wrong...</div>;

export const Pending = () => <div>Loading...</div>;

export default function SettingsLayout() {
	return <SettingsPage />;
}
