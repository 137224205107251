import React, { useCallback } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';
import { TgUserData } from '../types';
import { useTelegram } from '../services';
import {
	updateTelegramChatState,
	updateTelegramUserState,
} from '../redux/reducers/telegram-state.reducer';
import { RootStore } from '../redux/createStore';
import { fetchTgChat } from '../services/telegram/utils';
import { sixHoursInMilliseconds } from '../constants';
import { useTelegramLock } from '../services/telegram/lockContext';

type IPayload = {
	chatId?: number;
	includeUsernamesFetch?: boolean;
	isEnabled: boolean;
};

export const useGetChatInfo = ({
	chatId,
	isEnabled,
	includeUsernamesFetch,
}: IPayload) => {
	const queryClient = useQueryClient();
	const tg = useTelegram();
	const dispatch = useDispatch();
	const lock = useTelegramLock();
	const isSynced = useSelector(
		(state: RootStore) => state.syncState.isSynced,
	);

	const filterUniqueById = (arr: TgUserData[]) => {
		const seenIds = new Set<number>();
		return arr.filter(el => {
			if (seenIds.has(+el.id)) {
				return false;
			}
			seenIds.add(+el.id);
			return true;
		});
	};

	const updateUserState = useCallback(
		(id: number, userData: TgUserData) => {
			dispatch(updateTelegramUserState({ id, data: userData }));
		},
		[dispatch],
	);

	const fetchData = useCallback(async () => {
		if (!tg || !chatId) {
			return null;
		}

		const chatInfo = await lock.scheduleEvent(tg =>
			fetchTgChat({
				chatId,
				queryClient,
				includeUsernamesFetch,
				tg,
			}),
		);

		if (includeUsernamesFetch) {
			const chatMembers: TgUserData[] =
				chatInfo?.users?.map(user => {
					const userData = {
						id: +user.id,
						username: user.usernames?.[0]?.username || null,
					};
					updateUserState(+user.id, userData);
					return userData;
				}) || [];
			updateTelegramChatState({
				id: +chatId,
				data: {
					chatMembers: filterUniqueById(chatMembers),
					isChatFullyLoaded: true,
				},
			});
		}

		const chatAvatar =
			chatInfo?.chatFullInfo?.profilePhoto?.thumbnail?.dataUri || '';

		return chatAvatar;
	}, [chatId, queryClient, tg, includeUsernamesFetch, updateUserState]);

	const { data: chatAvatar } = useQuery({
		queryKey: ['tg-api-card-chat-info', chatId],
		queryFn: async () => {
			if (!tg || !chatId) {
				return null;
			}

			return fetchData();
		},
		enabled: isEnabled && !!tg && !!chatId && !!isSynced,
		staleTime: sixHoursInMilliseconds,
		refetchOnWindowFocus: false,
	});

	return chatAvatar;
};
