import React, { useEffect, useMemo, useState } from 'react';
import { Grid } from '@chakra-ui/react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { KanbanBoardColumn, KanbanLoadingOverlay } from '../components';
import { useLoadingProgress } from '../components/KanbanLoadingOverlay';
import { BoardCardItemResponse, BoardFilterValue } from '../types';
import {
    useGetWorkspaceCardsStatuses,
    useUpdateWorkspaceCardStatus,
} from '../../../queries';
import {
    CardDefaultStatusEnum,
    CardStatusItem,
    TgDataForCard,
} from '../../../types';
import { useUpdateBoardCardData } from '../hooks';
import { useUpdateArchiveVisibility } from '../queries';
import { moveTask, tasksAtom, TasksGroupedByStatus } from '@atoms/tasksAtom';
import { useAtom } from 'jotai';

interface DashboardPanelTemplateTabsProps {
    tabIndex: BoardFilterValue;
    data: BoardCardItemResponse[];
    workspaceId?: number;
    tgChatsData: Record<string, TgDataForCard>;
    isArchiveVisible?: boolean;
    teamTelegramIds?: number[];
}

const parseArrayByStatusId = (
    cards: BoardCardItemResponse[],
    statuses: CardStatusItem[],
) => {
    const cardsByStatus: TasksGroupedByStatus = {};

    statuses.forEach((status) => {
        cardsByStatus[status.id] = [];
    });

    // Group cards by status ID
    cards.forEach((card) => {
        const statusId = card.status.id;
        if (cardsByStatus.hasOwnProperty(statusId)) {
            cardsByStatus[statusId].push(card);
        }
    });

    return cardsByStatus;
};

const isObjEmpty = (obj: { [key: string]: BoardCardItemResponse[] }) =>
    Object.keys(obj).length === 0;

export const DashboardPanelTemplateTabs =
    React.memo<DashboardPanelTemplateTabsProps>(
        ({
            tabIndex,
            data,
            workspaceId,
            tgChatsData,
            isArchiveVisible,
            teamTelegramIds,
        }) => {
            const { data: statusesData } = useGetWorkspaceCardsStatuses();
            const { updateBoardCardData } = useUpdateBoardCardData();
            const { mutateAsync: updateWorkspaceCardStatus } =
                useUpdateWorkspaceCardStatus();
            const { mutateAsync: updateArchiveVisibility } =
                useUpdateArchiveVisibility();

            const { setTotal, resetState } = useLoadingProgress();

            React.useEffect(() => {
                // Only set total if there are actually unloaded chats
                const unloadedTasksCount = data.filter(
                    (task) => !tgChatsData[task.chatTelegramId]?.isLoaded,
                ).length;

                if (unloadedTasksCount > 0) {
                    setTotal(unloadedTasksCount);
                }

                return () => {
                    resetState();
                };
            }, []);

            const statuses = useMemo(
                () => statusesData?.value?.statuses,
                [statusesData],
            );
            const tasksDefault = useMemo(
                () => parseArrayByStatusId(data, statuses || []),
                [data, statuses],
            );
            React.useEffect(() => {
                setTasks(tasksDefault);
            }, []);
            const [tasks, setTasks] = useAtom(tasksAtom);

            const [prevData, setPrevData] = useState(JSON.stringify(data));

            useEffect(() => {
                if (!isObjEmpty(tasksDefault) && isObjEmpty(tasks)) {
                    setTasks(tasksDefault);
                }
            }, [tasksDefault, tasks]);

            if (JSON.stringify(data) !== prevData) {
                setPrevData(JSON.stringify(data));
                setTasks(tasksDefault);
            }

            const onDragEnd = async (result: DropResult) => {
                const { source, destination } = result;

                // If dropped outside the droppable area or in the same column
                if (
                    !destination ||
                    !workspaceId ||
                    source.droppableId === destination.droppableId
                ) {
                    return;
                }

                const movedTask = tasks[source.droppableId][source.index];

                setTasks(
                    moveTask({
                        tasks,
                        sourceStatusId: source.droppableId,
                        destinationStatusId: destination.droppableId,
                        sourceIndex: source.index,
                        destinationIndex: destination.index,
                    }),
                );
                const res = await updateWorkspaceCardStatus({
                    workspaceId,
                    cardId: movedTask.cardId,
                    statusId: +destination.droppableId,
                });

                if (!res.success) {
                    setTasks(tasks);
                    return;
                }

                updateBoardCardData(movedTask.cardId, +destination.droppableId);
            };

            return (
                <DragDropContext onDragEnd={onDragEnd}>
                    <Grid
                        id="dashboard-kanban-grid"
                        templateColumns={`repeat(${statuses?.length}, minmax(280px, 1fr))`}
                        w="100%"
                        h="calc(100vh - 118px)"
                        overflow="auto"
                    >
                        {statuses?.map((status, index) => {
                            const isArchiveColumn =
                                status.typeId === CardDefaultStatusEnum.ARCHIVE;

                            return (
                                <KanbanBoardColumn
                                    key={status.id}
                                    tabIndex={tabIndex}
                                    columnStatus={status}
                                    gridProps={{
                                        borderRightWidth:
                                            index === statuses.length - 1
                                                ? '0px'
                                                : '1px',
                                    }}
                                    enableColumnIcon={isArchiveColumn}
                                    isColumnIconActive={
                                        isArchiveColumn
                                            ? isArchiveVisible
                                            : false
                                    }
                                    onClickColumnIcon={() => {
                                        if (workspaceId && isArchiveColumn) {
                                            updateArchiveVisibility({
                                                workspaceId,
                                                isArchiveVisible:
                                                    !isArchiveVisible,
                                            });
                                        }
                                    }}
                                    teamTgIds={teamTelegramIds || []}
                                />
                            );
                        })}
                    </Grid>

                    <KanbanLoadingOverlay />
                </DragDropContext>
            );
        },
    );
