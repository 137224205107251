import { SidebarContent } from './SidebarContent';
import { CurrentUser } from '../../../types';

interface SidebarProps {
	data: CurrentUser;
}

export const Sidebar = ({ data }: SidebarProps) => {
	return <SidebarContent data={data} />;
};
