import React, { useCallback, useMemo } from 'react';
import {
	Box,
	GridItem,
	GridItemProps,
	HStack,
	IconButton,
	Text,
	VStack,
} from '@chakra-ui/react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { CustomTag, Icon, TaskCardSolo } from '../../../components';
import { BoardCardItemResponse, BoardFilterValue } from '../types';
import {
	getColumnDragBackgroundColor,
	sortCardsByDateAndAccess,
	getTaskStatusByType,
	getAllCards,
	getUnreadCards,
	getUnansweredCards,
	getActiveTasksCards,
	getTeamActivityCards,
	getMentionedCards,
} from '../../../utils';
import { CardStatusItem, TgDataForCard } from '../../../types';
import {
	setCardsFilterId,
	setCardsStatusId,
} from '../../../redux/reducers/page-navigation-state.reducer';
import { addRecentEntry } from '../../../redux/reducers/search-recent-stack.reducer';
import { useGetWorkspaceId } from '../../../hooks';
import { useAtomValue } from 'jotai';
import { tasksAtom } from '@atoms/tasksAtom';
import { RootStore } from '../../../redux/createStore';
import { DraggableTaskCard } from './DraggableTaskCard';

interface KanbanBoardColumnProps {
	// tasks: BoardCardItemResponse[];
	gridProps?: GridItemProps;
	tabIndex: BoardFilterValue;
	columnStatus: CardStatusItem;
	enableColumnIcon?: boolean;
	isColumnIconActive?: boolean;
	onClickColumnIcon?: VoidFunction;
	teamTgIds: number[];
}

export const KanbanBoardColumn: React.FC<KanbanBoardColumnProps> = ({
	// tasks,
	gridProps,
	tabIndex,
	columnStatus,
	enableColumnIcon,
	isColumnIconActive,
	onClickColumnIcon,
	teamTgIds,
}) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const workspaceId = useGetWorkspaceId();
	const {
		name: statusName,
		id: statusId,
		typeId: statusSystemDefaultId,
		position,
	} = columnStatus;

	const activeLabelId = useSelector(
		(state: RootStore) => state.dashboardState.activeLabelId,
	);

	const tgChatsData = useSelector(
		(state: RootStore) => state.telegramState.chats,
	);

	const dataByColumnType = useMemo(
		() => getTaskStatusByType({ position, typeId: statusSystemDefaultId }),
		[position, statusSystemDefaultId],
	);

	const onRedirect = (obj: {
		cardData: BoardCardItemResponse;
		statusId?: number;
		filterId?: number;
	}) => {
		navigate(`/${workspaceId}/chat/${obj.cardData.cardId}`, {
			state: {
				statusId: obj.statusId,
				filterId: obj.filterId,
				chatTelegramId: obj.cardData.chatTelegramId,
			},
		});
		dispatch(setCardsStatusId(obj.statusId || 0));
		dispatch(setCardsFilterId(obj.filterId || 1));
		dispatch(addRecentEntry(obj.cardData));
	};

	const tasks = useAtomValue(tasksAtom);
	const items = tasks[statusId];

	const getFilteredCards = useCallback(() => {
		let res: BoardCardItemResponse[] = [];
		const cards = items || [];

		switch (tabIndex) {
			case BoardFilterValue.All:
				res = getAllCards({ cards, activeLabelId });
				break;
			case BoardFilterValue.Unread:
				res = getUnreadCards({
					cards,
					tgChats: tgChatsData || {},
					activeLabelId,
				});
				break;
			case BoardFilterValue.Unanswered:
				res = getUnansweredCards({
					cards,
					tgChats: tgChatsData || {},
					teamTelegramIds: teamTgIds || [],
					activeLabelId,
				});
				break;
			case BoardFilterValue.ActiveTasks:
				res = getActiveTasksCards({
					cards,
					activeLabelId,
				});
				break;
			case BoardFilterValue.TeamActivity:
				res = getTeamActivityCards({
					cards,
					activeLabelId,
				});
				break;
			case BoardFilterValue.Mentions:
				res = getMentionedCards({
					cards,
					activeLabelId,
				});
				break;
			default:
				res = [];
		}

		const sortedCards = res?.sort((cardA, cardB) =>
			sortCardsByDateAndAccess(cardA, cardB, tgChatsData),
		);

		return sortedCards;
	}, [tabIndex, activeLabelId, items, tgChatsData, teamTgIds]);

	// Pre-calculate background colors
	const backgroundColors = useMemo(
		() => ({
			default: 'transparent',
			draggingOver: getColumnDragBackgroundColor(true, false),
			draggingFrom: getColumnDragBackgroundColor(false, true),
		}),
		[],
	);

	const sortedTasks = getFilteredCards();

	return (
		<GridItem
			p={2}
			borderRightWidth="1px"
			borderRightColor="gray.15"
			height="100%"
			{...gridProps}>
			<VStack align="flex-start" w="100%" spacing={1.5} height="100%">
				<HStack justify="space-between" spacing={2} w="full">
					<HStack spacing={2}>
						<CustomTag
							label={statusName}
							bgColor={dataByColumnType.bgColor}
							labelColor={dataByColumnType.textColor}
						/>
						{sortedTasks.length ? (
							<Text>{sortedTasks.length}</Text>
						) : null}
					</HStack>
					{enableColumnIcon ? (
						<IconButton
							aria-label={
								isColumnIconActive
									? 'hide column cards'
									: 'show column cards'
							}
							minW="auto"
							boxSize="24px"
							variant="outline"
							borderRadius="4px"
							bg="transparent"
							onClick={onClickColumnIcon}>
							<Icon
								name={isColumnIconActive ? 'eye-off ' : 'eye'}
								height="16"
								width="16"
							/>
						</IconButton>
					) : null}
				</HStack>
				<Box w="100%" h="100%">
					<Droppable droppableId={`${statusId}`} type="tasks">
						{(provided, snapshot) => (
							<Box
								w="100%"
								h="100%"
								ref={provided.innerRef}
								transition="background .15s ease-in-out"
								bg={
									snapshot.isDraggingOver
										? backgroundColors.draggingOver
										: snapshot.draggingFromThisWith
											? backgroundColors.draggingFrom
											: backgroundColors.default
								}
								{...provided.droppableProps}>
								{sortedTasks?.map((task, index) => {
									if (
										enableColumnIcon &&
										isColumnIconActive
									) {
										return null;
									}

									return (
										<DraggableTaskCard
											key={task.cardId}
											task={task}
											index={index}
											columnStatusId={columnStatus.id}
											tabIndex={tabIndex}
											teamTgIds={teamTgIds}
											onRedirect={onRedirect}
										/>
									);
								})}
								{provided.placeholder}
							</Box>
						)}
					</Droppable>
				</Box>
			</VStack>
		</GridItem>
	);
};
