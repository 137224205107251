import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/600.css';
import '@fontsource/inter/700.css';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { ChakraProvider } from '@chakra-ui/react';
import {
	useLocation,
	useNavigationType,
	createRoutesFromChildren,
	matchRoutes,
} from 'react-router-dom';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import * as Sentry from '@sentry/react';
import { theme } from './theme';
import { TelegramWindowProvider } from './services';
import { queryClient, persister } from './constants';

import '@radix-ui/themes/styles.css';
import 'virtual:svg-icons-register';
import './index.css';
import { AnalyticsContextProvider } from './analytics/context';
import { persistor, store } from './redux/createStore';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { Routes } from '@generouted/react-router';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

if (window.location.hostname !== 'localhost') {
	Sentry.init({
		dsn: 'https://6bf7a018897b41b749404acb98478b02@o4507022390525952.ingest.us.sentry.io/4507062748512256',
		integrations: [
			Sentry.reactRouterV6BrowserTracingIntegration({
				useEffect: React.useEffect,
				useLocation,
				useNavigationType,
				createRoutesFromChildren,
				matchRoutes,
			}),
			Sentry.browserTracingIntegration(),
			Sentry.replayIntegration(),
		],
		tracesSampleRate: 1.0,
		replaysSessionSampleRate: 0.1,
		replaysOnErrorSampleRate: 1.0,
		ignoreErrors: ['Rage Click'],
		beforeSend(event) {
			if (event && event.type === 'rageClick') {
				return null;
			}
			return event;
		},
	});
}

ReactDOM.createRoot(document.getElementById('root')!).render(
	<AnalyticsContextProvider writeKey={import.meta.env.VITE_JUNE_WRITE_KEY}>
		<PersistQueryClientProvider
			client={queryClient()}
			persistOptions={{ persister }}>
			<TelegramWindowProvider>
				<ChakraProvider theme={theme}>
					<Provider store={store}>
						<PersistGate loading={null} persistor={persistor}>
							<Routes />
						</PersistGate>
					</Provider>
				</ChakraProvider>
			</TelegramWindowProvider>
			<ReactQueryDevtools initialIsOpen={false} />
		</PersistQueryClientProvider>
	</AnalyticsContextProvider>,
);
