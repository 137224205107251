import { Outlet, useLocation } from 'react-router-dom';
import Redirects from '../Redirects';
import { useDispatch, useSelector } from 'react-redux';
import { Sidebar } from '../components/MainLayout/components';
import { useGetWorkspaces } from '@queries/useGetWorkspaces';
import { RootStore } from '../redux/createStore';
import { useEffect } from 'react';
import {
	setOnboardingActiveStep,
	setOnboardingVisibility,
} from '../redux/reducers/onboarding.reducer';
import { OnboardingSteps } from '../types';
import {
	CreateWorkspaceModal,
	InviteMembersModal,
	OnboardingModal,
	SelectTeamChatModal,
	SyncSettingsModal,
} from '../modals';
import { ChatPageHeader } from '../widgets';
import { TelegramHiddenFrame } from '../components';
import css from './_app.module.css';

export const Catch = () => {
	return <div>Something went wrong... Caught at _app error boundary</div>;
};

export const Pending = () => <div>Loading from _app...</div>;

export default function App() {
	const dispatch = useDispatch();

	const { pathname } = useLocation();

	const isChat = pathname.includes('/chat');
	const isOnboarding = pathname.includes('/onboarding');
	const isSelectWorkspace = pathname.includes('/select-workspace');
	const isLogin = pathname.includes('/login');

	const { data: workspacesData } = useGetWorkspaces();

	const { user } = useSelector((state: RootStore) => state.auth);

	const { workSpace, isOnboarded } = useSelector(
		(state: RootStore) => state.auth.user,
	);

	const { isOpen } = useSelector((state: RootStore) => state.onboarding);

	useEffect(() => {
		if (
			!workSpace &&
			user.id &&
			!isLogin &&
			!workspacesData?.value?.length
		) {
			dispatch(setOnboardingVisibility(true));
		} else if (!isOnboarded && !isOpen && user.id && !isLogin) {
			dispatch(setOnboardingActiveStep(OnboardingSteps.SYNC_CHATS));
			dispatch(setOnboardingVisibility(true));
		}
	}, [
		workSpace,
		isOnboarded,
		isOpen,
		user,
		workspacesData,
		dispatch,
		isLogin,
	]);

	return (
		<>
			<Redirects />
			<section className={css.layout}>
				{!isLogin && !isOnboarding && !isSelectWorkspace ? (
					<Sidebar
						data={
							user || {
								avatarThumbnailLink: '',
								avatarUrl: '',
								firstName: '',
								id: 0,
								isOnboarded: true,
								lastName: '',
							}
						}
					/>
				) : null}

				<main className={css.main}>
					{isChat ? <ChatPageHeader /> : null}

					<section className={css.sublayout}>
						<TelegramHiddenFrame />

						<Outlet />
					</section>
				</main>
			</section>

			<OnboardingModal />

			<InviteMembersModal />

			<SyncSettingsModal />

			<CreateWorkspaceModal />

			<SelectTeamChatModal />
		</>
	);
}
