import { BoardCardItemResponse } from '../features/Dashboard/types';
import { TgDataForCard } from '../types';

export const sortCardsByDateAndAccess = (
	cardA: BoardCardItemResponse,
	cardB: BoardCardItemResponse,
	tgChatsData: Record<string, TgDataForCard>,
) => {
	const tgChatA = tgChatsData[cardA.chatTelegramId];
	const tgChatB = tgChatsData[cardB.chatTelegramId];

	if (!tgChatA) {
		return 1;
	}
	if (!tgChatB) {
		return -1;
	}
	const tgChatALastMessageDate = tgChatA.lastMessage?.date || 0;
	const tgChatBLastMessageDate = tgChatB.lastMessage?.date || 0;

	return tgChatBLastMessageDate - tgChatALastMessageDate;
};
