import { useMemo } from 'react';
import { Avatar, AvatarGroup } from '@chakra-ui/react';
import { BaseUserData } from '../../types';

interface CustomAvatarGroupProps {
	items: BaseUserData[];
	avatars: Record<string, string>;
}

export const CustomAvatarGroup = ({
	items,
	avatars,
}: CustomAvatarGroupProps) => {
	const renderAvatars = useMemo(
		() =>
			items.map(user => {
				const avatarSrc = avatars[user.telegramUserId || ''];

				return (
					<Avatar
						key={user.id}
						src={avatarSrc}
						name={`${user.firstName} ${user.lastName || ''}`}
					/>
				);
			}),
		[items, avatars],
	);

	return (
		<AvatarGroup max={3} size="xs">
			{renderAvatars}
		</AvatarGroup>
	);
};
