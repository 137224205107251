import SelectWorkspace from '../../features/SelectWorkspace/SelectWorkspace';
import { AuthWrapper } from '../../widgets';

export default function SelectWorkspacePage() {
	return (
		<AuthWrapper>
			<SelectWorkspace />
		</AuthWrapper>
	);
}
