import { useMutation } from '@tanstack/react-query';
import API from '../../../api/api.base';
import { ApiResponseEmpty, TGChat } from '../../../types';
import { QueryKeys } from '../../../constants';
import { Alerter } from '../../../utils';

export const useSyncFolders = (includeSkip?: boolean) => {
	return useMutation<
		ApiResponseEmpty,
		unknown,
		{
			workspaceId: number;
			folders: { telegramFolderId: number; chats: TGChat[] }[];
		}
	>({
		mutationKey: [QueryKeys.SYNC_FOLDERS],
		mutationFn: payload => API.post('/api/cards/sync', payload),
		onSuccess: (data, variables) => {
			if (!data.success) {
				Alerter.error(data?.errors?.[0]?.message);
				return;
			}
			const { folders } = variables;

			if (!folders.length && includeSkip) {
				return;
			}
			Alerter.success('Chats sync settings updated!');
		},
	});
};
