import { AnalyticsBrowser } from '@june-so/analytics-next';
import React from 'react';
import { useJune } from './useJune';

export const AnalyticsContext = React.createContext<
	AnalyticsBrowser | undefined
>(undefined);

export const AnalyticsContextProvider: React.FCC<{
	writeKey: string;
}> = ({ children, writeKey }) => {
	const analytics = useJune(writeKey);
	return (
		<AnalyticsContext.Provider value={analytics}>
			{children}
		</AnalyticsContext.Provider>
	);
};

export const useAnalytics = () => {
	const context = React.useContext(AnalyticsContext);
	if (context === undefined) {
		throw new Error(
			'useAnalytics must be used within a AnalyticsContextProvider',
		);
	}
	return context;
};
